


























































import { defineComponent, computed } from "@vue/composition-api";
import Logo from "@dmp/frontend-framework/assets/logo_rgb.svg";
import LocalizationSwitcher from "./LocalizationSwitcher.vue";
import useOidc from "@/composables/useOidc";
import useProfile from "@/composables/useProfile";
import { RouteMeta } from "vue-router/types/router";

export default defineComponent({
  components: {
    Logo,
    LocalizationSwitcher,
  },
  setup(_, { root }) {
    const {
      roles,
      brugerstyringRoles,
      userHome,
      canChangeProfileEmail,
      profile,
    } = useProfile();

    var routes = computed(() =>
      root.$router
        .getRoutes()
        .filter(
          (route) =>
            route.meta.includeInNavigationBar &&
            route.meta.includeInNavigationBar()
        )
        .filter((route) =>
          (route.meta as RouteMeta).roleRequirements?.some((role) =>
            brugerstyringRoles.value?.some(
              (element) => element.attribute == role
            )
          )
        )
    );

    function logOut() {
      useOidc().logOut();
    }

    return {
      userName: computed(() => profile.value?.fullname),
      isLoggedIn: computed(() => useOidc().user.value != null),
      routes,
      userHome,
      logOut,
      canChangeProfileEmail,
    };
  },
});
