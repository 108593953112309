























import { defineComponent } from "@vue/composition-api";
import SupportedLocales, { ILocale } from "@/locales/supported-locales";
import { LSK_CURRENT_LANGUAGE } from "@/constants";

export default defineComponent({
  setup(_, { root }) {
    return {
      languages: SupportedLocales,
      localeSelected: (locale: ILocale) => {
        root.$i18n.locale = locale.code;
        localStorage.setItem(LSK_CURRENT_LANGUAGE, locale.code);
      },
    };
  },
});
