import gql from "graphql-tag";
import * as VueApolloComposable from "@vue/apollo-composable";
import * as VueCompositionApi from "@vue/composition-api";
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: any;
  Guid: any;
  /** A meta type that represents a file upload. */
  Upload: any;
};

export type AddOrRemoveRoleAssigneeInput = {
  roleId: Scalars["Guid"];
  organizationId: Scalars["Guid"];
};

export type AddOrRemoveUserApplicationAssignmentInput = {
  userId: Scalars["Guid"];
  applicationId: Scalars["Guid"];
};

export type AddOrRemoveUserApplicationOwnerInput = {
  userId: Scalars["Guid"];
  applicationId: Scalars["Guid"];
};

export type Application = {
  __typename?: "Application";
  id?: Maybe<Scalars["Guid"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  coverImageUrl?: Maybe<Scalars["String"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  ownerOrganizationId?: Maybe<Scalars["Guid"]>;
  connectionId?: Maybe<Scalars["Guid"]>;
  ownerOrganization?: Maybe<Organization>;
  roles?: Maybe<RoleGraphTypeConnection>;
  /**
   * 1. if the user belongs to an organization in BS, AND if there is one or more application administrators, we should the first one
   * 2. if the user belongs to an organization in BS, AND if there is no application administrator we show the first local administrator
   */
  contactUser?: Maybe<User>;
  /** Owners (system owners) of application */
  owners?: Maybe<Array<Maybe<User>>>;
  /** Get all role assignments of context application */
  roleAssignments?: Maybe<Array<Maybe<UserRoleAssignment>>>;
};

export type ApplicationContactUserArgs = {
  userId: Scalars["Guid"];
};

export type ApplicationRoleAssignmentsArgs = {
  isTestUserOnly?: Maybe<Scalars["Boolean"]>;
};

export type ApplicationGraphTypeConnection = {
  __typename?: "ApplicationGraphTypeConnection";
  totalCount?: Maybe<Scalars["Int"]>;
  nodes?: Maybe<Array<Maybe<Application>>>;
};

export type ApplicationLoginsReport = {
  __typename?: "ApplicationLoginsReport";
  /** List of report items. An item is user logins in specified application */
  userLogins?: Maybe<Array<Maybe<ApplicationLoginsReportItem>>>;
};

export type ApplicationLoginsReportItem = {
  __typename?: "ApplicationLoginsReportItem";
  /** Application's Id */
  applicationId?: Maybe<Scalars["Guid"]>;
  /** Application name */
  applicationName?: Maybe<Scalars["String"]>;
  /** User's fullname */
  userFullname?: Maybe<Scalars["String"]>;
  /** Organization's number */
  cvr?: Maybe<Scalars["String"]>;
  /** Organization's name */
  organizationName?: Maybe<Scalars["String"]>;
  /** Number of logins of user in specified application */
  noOfLogins?: Maybe<Scalars["Int"]>;
};

export type ApplicationMutation = {
  __typename?: "ApplicationMutation";
  createApplication?: Maybe<Application>;
  updateApplication?: Maybe<Application>;
  updateApplicationConnectionId?: Maybe<Application>;
  deleteApplication?: Maybe<Scalars["Guid"]>;
  /** Assign a user to be an application owner. Note: only users in the same organization of application's organization are allowed */
  assignOwner: Application;
  /** Remove application owner */
  removeOwner: Application;
};

export type ApplicationMutationCreateApplicationArgs = {
  input: CreateApplicationInput;
};

export type ApplicationMutationUpdateApplicationArgs = {
  input: UpdateApplicationInput;
};

export type ApplicationMutationUpdateApplicationConnectionIdArgs = {
  input: UpdateApplicationConnectionIdInput;
};

export type ApplicationMutationDeleteApplicationArgs = {
  applicationId: Scalars["Guid"];
};

export type ApplicationMutationAssignOwnerArgs = {
  input: AddOrRemoveUserApplicationOwnerInput;
};

export type ApplicationMutationRemoveOwnerArgs = {
  input: AddOrRemoveUserApplicationOwnerInput;
};

export type AssignUserRoleInput = {
  /** Role's id that want to assign */
  roleId: Scalars["Guid"];
  /** User's id that want to assign */
  userId: Scalars["Guid"];
  /** Indicate that the assignment is OBO or not. True or Null: OBO assignement (organizationId is logged in user's own organization); False: not OBO assignment(organizationId is context user's own organization) */
  isOBOAssignment?: Maybe<Scalars["Boolean"]>;
  expirationTime?: Maybe<Scalars["DateTime"]>;
};

export type AssignUserRoleOnBehalfOfInput = {
  email: Scalars["String"];
  roles: Array<Maybe<OnBehalfOfRoleInput>>;
};

export type BrugerstyringQuery = {
  __typename?: "BrugerstyringQuery";
  organization?: Maybe<Organization>;
  organizationByCvr?: Maybe<Organization>;
  /** This endpoint is used to validate unique name globally */
  organizationByName?: Maybe<Organization>;
  organizations?: Maybe<OrganizationGraphTypeConnection>;
  defaultOrganization?: Maybe<Organization>;
  application?: Maybe<Application>;
  /** This endpoint is used to validate unique name globally */
  applicationByName?: Maybe<Application>;
  /** Gets the application information by the application connection ID. This endpoint is used to create new connections in Identify. */
  applicationByConnectionId?: Maybe<Application>;
  applications?: Maybe<ApplicationGraphTypeConnection>;
  brugerstyringApplication?: Maybe<Application>;
  role?: Maybe<Role>;
  roleByAttribute?: Maybe<Role>;
  roles?: Maybe<RoleGraphTypeConnection>;
  user?: Maybe<User>;
  userByEmail?: Maybe<User>;
  userByNemloginId?: Maybe<User>;
  userByUpstreamIdpUserIdAndCvr?: Maybe<User>;
  users?: Maybe<UserGraphTypeList>;
  /** Return the user information of context user */
  profile?: Maybe<UserProfile>;
  /** Return the user information of context user */
  userProfile?: Maybe<User>;
  /** Return simulation token of context user */
  userSimulationToken?: Maybe<Array<Maybe<KeyValuePair>>>;
  /** Get user role assignment by connectionId. This endpoint is used by Identify downstream when generating user token */
  userRolesByConnectionId?: Maybe<Array<Maybe<UserRoleAssignment>>>;
  histories?: Maybe<HistoryGraphTypeList>;
  applicationLoginsReport?: Maybe<ApplicationLoginsReport>;
};

export type BrugerstyringQueryOrganizationArgs = {
  id?: Maybe<Scalars["Guid"]>;
};

export type BrugerstyringQueryOrganizationByCvrArgs = {
  cvr: Scalars["String"];
};

export type BrugerstyringQueryOrganizationByNameArgs = {
  name: Scalars["String"];
};

export type BrugerstyringQueryApplicationArgs = {
  id?: Maybe<Scalars["Guid"]>;
};

export type BrugerstyringQueryApplicationByNameArgs = {
  name: Scalars["String"];
};

export type BrugerstyringQueryApplicationByConnectionIdArgs = {
  connectionId?: Maybe<Scalars["Guid"]>;
};

export type BrugerstyringQueryRoleArgs = {
  id?: Maybe<Scalars["Guid"]>;
};

export type BrugerstyringQueryRoleByAttributeArgs = {
  attribute: Scalars["String"];
};

export type BrugerstyringQueryUserArgs = {
  id?: Maybe<Scalars["Guid"]>;
};

export type BrugerstyringQueryUserByEmailArgs = {
  email: Scalars["String"];
};

export type BrugerstyringQueryUserByNemloginIdArgs = {
  nemloginId: Scalars["String"];
};

export type BrugerstyringQueryUserByUpstreamIdpUserIdAndCvrArgs = {
  uuid: Scalars["String"];
  cvr?: Maybe<Scalars["String"]>;
};

export type BrugerstyringQueryUsersArgs = {
  pageParams?: Maybe<PageParamsGraphType>;
  filters?: Maybe<UserFiltersInput>;
  sortInfo?: Maybe<UserSortInfo>;
};

export type BrugerstyringQueryUserSimulationTokenArgs = {
  applicationId: Scalars["Guid"];
  organizationId: Scalars["Guid"];
};

export type BrugerstyringQueryUserRolesByConnectionIdArgs = {
  input: GetUserRoleAssignementsByApplicationInput;
};

export type BrugerstyringQueryHistoriesArgs = {
  id: Scalars["Guid"];
  pageParams?: Maybe<PageParamsGraphType>;
};

export type BrugerstyringQueryApplicationLoginsReportArgs = {
  id: Scalars["Guid"];
  fromDate: Scalars["DateTime"];
  toDate: Scalars["DateTime"];
};

export type ConfirmEmailVerificationInput = {
  /** User's Guid in BS system */
  userId: Scalars["Guid"];
  codeChallenge: Scalars["String"];
};

export type ConfirmLinkNemLoginIdInput = {
  /** User's Id,that existing in BS2 system, who will be mapped with nemLoginId */
  userId: Scalars["Guid"];
  /** User's NemLoginId */
  nemLoginId: Scalars["String"];
  /** Id of NemLoginId Identify Provider */
  nemLoginIdpId: Scalars["Guid"];
  codeChallenge: Scalars["String"];
};

export type CreateApplicationInput = {
  ownerOrganizationId: Scalars["Guid"];
  name: Scalars["String"];
  description: Scalars["String"];
  coverImageUrl?: Maybe<Scalars["String"]>;
  coverImageFile?: Maybe<Scalars["Upload"]>;
  logoFile?: Maybe<Scalars["Upload"]>;
  roles?: Maybe<Array<Maybe<CreateRoleInput>>>;
};

export type CreateOrganizationInput = {
  vat: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  logoFile?: Maybe<Scalars["Upload"]>;
  organizationContacts?: Maybe<Array<Maybe<OrganizationContactInput>>>;
};

export type CreateRoleInput = {
  /** Application of role. if the role is created in application context, this field should be null */
  applicationId?: Maybe<Scalars["Guid"]>;
  name: Scalars["String"];
  attribute: Scalars["String"];
  description: Scalars["String"];
  /** AssigneeIds */
  organizationsIds?: Maybe<Array<Maybe<Scalars["Guid"]>>>;
};

export type CreateTestUserInput = {
  /** Organization of user. You can get user's organization information(including organizationID) from CVR */
  organizationId?: Maybe<Scalars["Guid"]>;
  isTestUser?: Maybe<Scalars["Boolean"]>;
  email: Scalars["String"];
  firstname: Scalars["String"];
  lastname: Scalars["String"];
};

export type CreateUserInput = {
  /** Organization of user. You can get user's organization information(including organizationID) from CVR */
  organizationId?: Maybe<Scalars["Guid"]>;
  email: Scalars["String"];
  firstname: Scalars["String"];
  lastname: Scalars["String"];
  nemloginId?: Maybe<Scalars["String"]>;
  /** This contains list of nemlogin user's Ids in UpstreamIdp. This helps to map the user profile to IdP's. */
  upstreamIdpIds?: Maybe<Array<Maybe<UpstreamIdpIdInput>>>;
};

export enum EmailVerificationStatus {
  Expired = "EXPIRED",
  Verified = "VERIFIED",
  InvalidCodeChallenge = "INVALID_CODE_CHALLENGE",
}

export type EmailVerificationStatusEnum = {
  __typename?: "EmailVerificationStatusEnum";
  status?: Maybe<EmailVerificationStatus>;
};

export type GetUserRoleAssignementsByApplicationInput = {
  userId: Scalars["Guid"];
  /** Application's connection that is configurated in Indentify application */
  connectionId: Scalars["Guid"];
};

export type History = {
  __typename?: "History";
  userName?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  datetime?: Maybe<Scalars["DateTime"]>;
  action?: Maybe<Scalars["String"]>;
  details?: Maybe<Array<Maybe<HistoryDetail>>>;
};

export type HistoryDetail = {
  __typename?: "HistoryDetail";
  /** Property name */
  property?: Maybe<Scalars["String"]>;
  /** Value before changed */
  from?: Maybe<Scalars["String"]>;
  /** Value after changed */
  to?: Maybe<Scalars["String"]>;
  /** Optional. Id of reference from object */
  fromRefId?: Maybe<Scalars["Guid"]>;
  /** Optional. Id of reference to object */
  toRefId?: Maybe<Scalars["Guid"]>;
};

export type HistoryGraphTypeList = {
  __typename?: "HistoryGraphTypeList";
  nodes?: Maybe<Array<Maybe<History>>>;
  totalCount?: Maybe<Scalars["Int"]>;
  pageInfo?: Maybe<PageInfo>;
};

export type KeyValuePair = {
  __typename?: "KeyValuePair";
  key?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type LinkNemLoginIdUserInput = {
  /** User's Id,that existing in BS2 system, who will be mapped with nemLogiId */
  userId: Scalars["Guid"];
  /** User's NemLoginId */
  nemLoginId: Scalars["String"];
  /** Id of NemLogin Identify provider */
  nemLoginIdpId: Scalars["Guid"];
};

export type Mutation = {
  __typename?: "Mutation";
  organizationMutation?: Maybe<OrganizationMutation>;
  applicationMutation?: Maybe<ApplicationMutation>;
  roleMutation?: Maybe<RoleMutation>;
  userMutation?: Maybe<UserMutation>;
};

export type OnBehalfOfRoleInput = {
  roleId: Scalars["Guid"];
  expirationTime?: Maybe<Scalars["DateTime"]>;
};

export enum OrderDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type Organization = {
  __typename?: "Organization";
  id?: Maybe<Scalars["Guid"]>;
  vat?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  applications?: Maybe<Array<Maybe<Application>>>;
  /** Get number of users (TotalCount) in organization. Note: because of performance we only support get TotalCount only. If you want to get more user's files (e.g: search, filter ..), use 'users' query instead. */
  users?: Maybe<UserGraphTypeWithTotalOnlyList>;
  organizationSupporters?: Maybe<Array<Maybe<User>>>;
  assigneeRoles?: Maybe<Array<Maybe<Role>>>;
  assignedRoles?: Maybe<Array<Maybe<UserRoleAssignment>>>;
  organizationContacts?: Maybe<Array<Maybe<OrganizationContact>>>;
};

export type OrganizationUsersArgs = {
  pageParams?: Maybe<PageParamsGraphType>;
  filters?: Maybe<UserFiltersInput>;
  sortInfo?: Maybe<UserSortInfo>;
};

export type OrganizationAssignedRolesArgs = {
  excludeOwnUsers?: Maybe<Scalars["Boolean"]>;
};

export type OrganizationContact = {
  __typename?: "OrganizationContact";
  id?: Maybe<Scalars["Guid"]>;
  name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
};

export type OrganizationContactInput = {
  name: Scalars["String"];
  phone: Scalars["String"];
  email: Scalars["String"];
};

export type OrganizationGraphTypeConnection = {
  __typename?: "OrganizationGraphTypeConnection";
  totalCount?: Maybe<Scalars["Int"]>;
  nodes?: Maybe<Array<Maybe<Organization>>>;
};

export type OrganizationMutation = {
  __typename?: "OrganizationMutation";
  createOrganization?: Maybe<Organization>;
  updateOrganization?: Maybe<Organization>;
  deleteOrganization?: Maybe<Scalars["Guid"]>;
};

export type OrganizationMutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type OrganizationMutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};

export type OrganizationMutationDeleteOrganizationArgs = {
  organizationId: Scalars["Guid"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  currentPage: Scalars["Int"];
  pageSize: Scalars["Int"];
  hasPrevious: Scalars["Boolean"];
  hasNext: Scalars["Boolean"];
};

export type PageParamsGraphType = {
  /** Current page number that need to get data. E.g: If you want to get first page, set this value = 1 */
  currentPage?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type RemoveUserRoleInput = {
  roleId: Scalars["Guid"];
  userId: Scalars["Guid"];
  /** On behalf of organization id */
  onBehalfOfOrganizationId: Scalars["Guid"];
};

export type Role = {
  __typename?: "Role";
  id?: Maybe<Scalars["Guid"]>;
  attribute?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  application?: Maybe<Application>;
  assignees?: Maybe<Array<Maybe<Organization>>>;
  assignedUsers?: Maybe<UserRoleAssignmentGraphTypeConnection>;
};

export type RoleGraphTypeConnection = {
  __typename?: "RoleGraphTypeConnection";
  totalCount?: Maybe<Scalars["Int"]>;
  nodes?: Maybe<Array<Maybe<Role>>>;
};

export type RoleMutation = {
  __typename?: "RoleMutation";
  createRole?: Maybe<Role>;
  updateRole?: Maybe<Role>;
  deleteRole?: Maybe<Scalars["Guid"]>;
  addRoleAssignee?: Maybe<Role>;
  removeRoleAssignee?: Maybe<Role>;
};

export type RoleMutationCreateRoleArgs = {
  input: CreateRoleInput;
};

export type RoleMutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};

export type RoleMutationDeleteRoleArgs = {
  roleId: Scalars["Guid"];
};

export type RoleMutationAddRoleAssigneeArgs = {
  input: AddOrRemoveRoleAssigneeInput;
};

export type RoleMutationRemoveRoleAssigneeArgs = {
  input: AddOrRemoveRoleAssigneeInput;
};

export type TrackTokenIssuanceInput = {
  /** User's Guid in BS system */
  userId: Scalars["Guid"];
  /** Application's connection that is configurated in Indentify application */
  connectionId: Scalars["Guid"];
  /** On behalf of organization id that user selected in downstream idp. This field can be null if token does not contain CVR */
  organizationId?: Maybe<Scalars["Guid"]>;
  /** If of Identify provider */
  idpId: Scalars["Guid"];
  /** Name of Identify provider. E.g: NemLogin */
  idpName: Scalars["String"];
  /** List of role's attribute that are issued for user */
  roles?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

/**
 * Track user login. This method is called by UpstreamIdp whenever user logged into any application.
 *                     BS system will update user's master data such as firstname, lastname and email.
 *                     If the email is null, BS will keep the existing email
 *                     If the email lis not null, BE will check unique and update user's email
 */
export type TrackUserLoginInput = {
  /** User's Guid in BS system */
  userId: Scalars["Guid"];
  firstname: Scalars["String"];
  lastname: Scalars["String"];
  /** Updated email. If email is null, ignore the updation */
  email?: Maybe<Scalars["String"]>;
  nemloginId?: Maybe<Scalars["String"]>;
  upstreamIdpId?: Maybe<UpstreamIdpIdInput>;
};

export type UpdateApplicationConnectionIdInput = {
  id: Scalars["Guid"];
  connectionId: Scalars["Guid"];
};

export type UpdateApplicationInput = {
  id: Scalars["Guid"];
  name: Scalars["String"];
  description: Scalars["String"];
  coverImageUrl: Scalars["String"];
  coverImageFile?: Maybe<Scalars["Upload"]>;
  ownerOrganizationId: Scalars["Guid"];
  /** Existing LogoUrl. If we want to delete existing logo, set this field is null */
  logoUrl?: Maybe<Scalars["String"]>;
  /** If this field exists, the logoFile will overwrite the existing one */
  logoFile?: Maybe<Scalars["Upload"]>;
};

export type UpdateOrganizationInput = {
  id: Scalars["Guid"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  logoFile?: Maybe<Scalars["Upload"]>;
  organizationContacts?: Maybe<Array<Maybe<OrganizationContactInput>>>;
  organizationSupportersId?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type UpdateRoleInput = {
  id: Scalars["Guid"];
  name: Scalars["String"];
  description: Scalars["String"];
  attribute: Scalars["String"];
};

export type UpdateUserInput = {
  /** User's Guid in BS system */
  userId: Scalars["Guid"];
  firstname: Scalars["String"];
  lastname: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
};

export type UpdateUserProfileInput = {
  userId: Scalars["Guid"];
  /** User's new email. After updating the new email, user needs to verify their new email address. */
  email: Scalars["String"];
};

export type UpdateUserRoleAssignmentExpiryDateInput = {
  roleId: Scalars["Guid"];
  userId: Scalars["Guid"];
  /** On behalf of organization id */
  onBehalfOfOrganizationId: Scalars["Guid"];
  expirationTime?: Maybe<Scalars["DateTime"]>;
};

export type UpstreamIdpId = {
  __typename?: "UpstreamIdpId";
  upstreamIdpId?: Maybe<Scalars["Guid"]>;
  upstreamIdpUserId?: Maybe<Scalars["String"]>;
};

export type UpstreamIdpIdInput = {
  /** Entity Id of Upstream Idp */
  upstreamIdpId: Scalars["Guid"];
  /** Generated UserId in Upstream Idp */
  upstreamIdpUserId: Scalars["String"];
};

export type User = {
  __typename?: "User";
  id?: Maybe<Scalars["Guid"]>;
  firstname?: Maybe<Scalars["String"]>;
  lastname?: Maybe<Scalars["String"]>;
  fullname?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  nemloginId?: Maybe<Scalars["String"]>;
  emailVerificationDate?: Maybe<Scalars["DateTime"]>;
  lastLogin?: Maybe<Scalars["DateTime"]>;
  isTestUser?: Maybe<Scalars["Boolean"]>;
  isEmailVerificationExpired?: Maybe<Scalars["Boolean"]>;
  organization?: Maybe<Organization>;
  roleAssignments?: Maybe<Array<Maybe<UserRoleAssignment>>>;
  upstreamIdpIds?: Maybe<Array<Maybe<UpstreamIdpId>>>;
  applicationAssignments?: Maybe<Array<Maybe<Application>>>;
  /**
   * Return linking NemLog-In status of this user.
   *                                Return false if this is not NemLog-In user or NemLog-In user who have not linked into Local Idp user yet.
   *                                Otherwise return true
   */
  isLinkedNemLogIn?: Maybe<Scalars["Boolean"]>;
};

/** Sortable fields of User */
export enum UserField {
  Name = "NAME",
  OrganizationName = "ORGANIZATION_NAME",
  Email = "EMAIL",
  LastSeen = "LAST_SEEN",
}

export enum UserFilterType {
  All = "ALL",
  Organization = "ORGANIZATION",
  AllBehalfOff = "ALL_BEHALF_OFF",
  TestUsersOnly = "TEST_USERS_ONLY",
}

export type UserFiltersInput = {
  name?: Maybe<Scalars["String"]>;
  /** Get users in within organizationId */
  organizationId?: Maybe<Scalars["Guid"]>;
  organizationName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  roleIds?: Maybe<Array<Maybe<Scalars["Guid"]>>>;
  /** There are 3 filter types: All Users, Users of owner organization and On Behalf Off Users. Default is All Users */
  filterType?: Maybe<UserFilterType>;
  /**
   * Indicate that we execute searching by contains logic or startWith logic
   *                                 E.g: If IsContainTextSearch = true, executing search by contains logic.
   *                                      If IsContainTextSearch = false, executing search by startWith logic that has better performance.
   *                                 Default is false
   */
  isContainTextSearch?: Maybe<Scalars["Boolean"]>;
  /** This flag indicate that the response include TestUser or not */
  includingTestUser?: Maybe<Scalars["Boolean"]>;
};

export type UserGraphTypeList = {
  __typename?: "UserGraphTypeList";
  nodes?: Maybe<Array<Maybe<User>>>;
  totalCount?: Maybe<Scalars["Int"]>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserGraphTypeWithTotalOnlyList = {
  __typename?: "UserGraphTypeWithTotalOnlyList";
  nodes?: Maybe<Array<Maybe<User>>>;
  totalCount?: Maybe<Scalars["Int"]>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserMutation = {
  __typename?: "UserMutation";
  createUser: Scalars["Guid"];
  createTestUser: User;
  /** Link a NemLoginId into existing user in BS2 system */
  linkNemLoginId: Scalars["Guid"];
  confirmLinkNemLoginId: EmailVerificationStatusEnum;
  updateUser: User;
  updateUserProfile: Scalars["Guid"];
  confirmEmailVerification: EmailVerificationStatusEnum;
  resendEmailVerification?: Maybe<Scalars["Guid"]>;
  deleteUser?: Maybe<Scalars["Guid"]>;
  /**
   * Track user login. This endpoint is called by UpstreamIdp whenever user logged into any application.
   *                             BS system will update user's master data such as firstname, lastname and email.
   *                             If the email is null, BS will keep the existing email
   *                             If the email lis not null, BE will check unique and update user's email
   */
  trackUserLogin: User;
  /**
   * Track token issuance. This API is call by Identify before issue a token to user.
   *                            BS system will log token information into token-issuance for generating report.
   */
  trackTokenIssuance?: Maybe<Scalars["Guid"]>;
  assignRole: User;
  assignRoleOnBehalfOf: User;
  removeRole: User;
  updateUserRoleAssignmentExpiryDate: UserRoleAssignment;
  assignApplication: User;
  removeApplication: User;
};

export type UserMutationCreateUserArgs = {
  input: CreateUserInput;
};

export type UserMutationCreateTestUserArgs = {
  input: CreateTestUserInput;
};

export type UserMutationLinkNemLoginIdArgs = {
  input: LinkNemLoginIdUserInput;
};

export type UserMutationConfirmLinkNemLoginIdArgs = {
  input: ConfirmLinkNemLoginIdInput;
};

export type UserMutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type UserMutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};

export type UserMutationConfirmEmailVerificationArgs = {
  input: ConfirmEmailVerificationInput;
};

export type UserMutationResendEmailVerificationArgs = {
  userId: Scalars["Guid"];
};

export type UserMutationDeleteUserArgs = {
  userId: Scalars["Guid"];
};

export type UserMutationTrackUserLoginArgs = {
  input: TrackUserLoginInput;
};

export type UserMutationTrackTokenIssuanceArgs = {
  input: TrackTokenIssuanceInput;
};

export type UserMutationAssignRoleArgs = {
  input: AssignUserRoleInput;
};

export type UserMutationAssignRoleOnBehalfOfArgs = {
  input: AssignUserRoleOnBehalfOfInput;
};

export type UserMutationRemoveRoleArgs = {
  input: RemoveUserRoleInput;
};

export type UserMutationUpdateUserRoleAssignmentExpiryDateArgs = {
  input: UpdateUserRoleAssignmentExpiryDateInput;
};

export type UserMutationAssignApplicationArgs = {
  input: AddOrRemoveUserApplicationAssignmentInput;
};

export type UserMutationRemoveApplicationArgs = {
  input: AddOrRemoveUserApplicationAssignmentInput;
};

export type UserProfile = {
  __typename?: "UserProfile";
  user?: Maybe<User>;
  oboOrganization?: Maybe<Organization>;
};

export type UserRoleAssignment = {
  __typename?: "UserRoleAssignment";
  user?: Maybe<User>;
  role?: Maybe<Role>;
  organization?: Maybe<Organization>;
  expirationDate?: Maybe<Scalars["DateTime"]>;
  lastUse?: Maybe<Scalars["DateTime"]>;
};

export type UserRoleAssignmentGraphTypeConnection = {
  __typename?: "UserRoleAssignmentGraphTypeConnection";
  totalCount?: Maybe<Scalars["Int"]>;
  nodes?: Maybe<Array<Maybe<UserRoleAssignment>>>;
};

export type UserSortInfo = {
  direction?: Maybe<OrderDirection>;
  field?: Maybe<UserField>;
};

export type GetHistoriesQueryVariables = Exact<{
  id: Scalars["Guid"];
  pageParams: PageParamsGraphType;
}>;

export type GetHistoriesQuery = { __typename?: "BrugerstyringQuery" } & {
  histories?: Maybe<
    { __typename?: "HistoryGraphTypeList" } & Pick<
      HistoryGraphTypeList,
      "totalCount"
    > & {
        nodes?: Maybe<
          Array<
            Maybe<
              { __typename?: "History" } & Pick<
                History,
                "userName" | "datetime" | "action"
              > & {
                  user?: Maybe<
                    { __typename?: "User" } & Pick<User, "id" | "fullname"> & {
                        organization?: Maybe<
                          { __typename?: "Organization" } & Pick<
                            Organization,
                            "id" | "name"
                          >
                        >;
                      }
                  >;
                  details?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "HistoryDetail" } & Pick<
                          HistoryDetail,
                          "property" | "from" | "to" | "fromRefId" | "toRefId"
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type GetSelectableApplicationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSelectableApplicationsQuery = {
  __typename?: "BrugerstyringQuery";
} & {
  applications?: Maybe<
    { __typename?: "ApplicationGraphTypeConnection" } & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: "Application" } & Pick<
              Application,
              "id" | "name"
            > & {
                ownerOrganization?: Maybe<
                  { __typename?: "Organization" } & Pick<
                    Organization,
                    "id" | "name" | "vat"
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type GetSelectableOrganizationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSelectableOrganizationQuery = {
  __typename?: "BrugerstyringQuery";
} & {
  organizations?: Maybe<
    { __typename?: "OrganizationGraphTypeConnection" } & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: "Organization" } & Pick<
              Organization,
              "id" | "name" | "vat"
            >
          >
        >
      >;
    }
  >;
};

export type GetSelectableRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetSelectableRolesQuery = { __typename?: "BrugerstyringQuery" } & {
  roles?: Maybe<
    { __typename?: "RoleGraphTypeConnection" } & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: "Role" } & Pick<
              Role,
              "id" | "attribute" | "name" | "description"
            > & {
                application?: Maybe<
                  { __typename?: "Application" } & Pick<
                    Application,
                    "id" | "name"
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserProfileQuery = { __typename?: "BrugerstyringQuery" } & {
  profile?: Maybe<
    { __typename?: "UserProfile" } & {
      oboOrganization?: Maybe<
        { __typename?: "Organization" } & Pick<
          Organization,
          "name" | "id" | "vat"
        > & {
            applications?: Maybe<
              Array<
                Maybe<
                  { __typename?: "Application" } & Pick<
                    Application,
                    "id" | "name"
                  >
                >
              >
            >;
            assigneeRoles?: Maybe<
              Array<
                Maybe<
                  { __typename?: "Role" } & Pick<Role, "id" | "name"> & {
                      application?: Maybe<
                        { __typename?: "Application" } & Pick<Application, "id">
                      >;
                    }
                >
              >
            >;
          }
      >;
      user?: Maybe<
        { __typename?: "User" } & Pick<
          User,
          | "id"
          | "firstname"
          | "lastname"
          | "fullname"
          | "description"
          | "emailVerificationDate"
          | "email"
          | "nemloginId"
          | "isLinkedNemLogIn"
        > & {
            roleAssignments?: Maybe<
              Array<
                Maybe<
                  { __typename?: "UserRoleAssignment" } & {
                    organization?: Maybe<
                      { __typename?: "Organization" } & Pick<
                        Organization,
                        "id" | "name"
                      >
                    >;
                    role?: Maybe<
                      { __typename?: "Role" } & Pick<
                        Role,
                        "id" | "attribute" | "name"
                      > & {
                          application?: Maybe<
                            { __typename?: "Application" } & Pick<
                              Application,
                              "id" | "name"
                            > & {
                                ownerOrganization?: Maybe<
                                  { __typename?: "Organization" } & Pick<
                                    Organization,
                                    "id" | "name"
                                  >
                                >;
                              }
                          >;
                        }
                    >;
                  }
                >
              >
            >;
            organization?: Maybe<
              { __typename?: "Organization" } & Pick<
                Organization,
                "name" | "id" | "vat"
              >
            >;
            applicationAssignments?: Maybe<
              Array<
                Maybe<
                  { __typename?: "Application" } & Pick<
                    Application,
                    "id" | "name"
                  >
                >
              >
            >;
          }
      >;
    }
  >;
};

export type SelectApplicationQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SelectApplicationQueryQuery = {
  __typename?: "BrugerstyringQuery";
} & {
  applications?: Maybe<
    { __typename?: "ApplicationGraphTypeConnection" } & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: "Application" } & Pick<Application, "name" | "id">
          >
        >
      >;
    }
  >;
};

export type CommonApplicationPartsFragment = {
  __typename?: "Application";
} & Pick<Application, "id" | "name"> & {
    ownerOrganization?: Maybe<
      { __typename?: "Organization" } & Pick<
        Organization,
        "id" | "name" | "logoUrl"
      >
    >;
    roles?: Maybe<
      { __typename?: "RoleGraphTypeConnection" } & Pick<
        RoleGraphTypeConnection,
        "totalCount"
      >
    >;
  };

export type AssignApplicationOwnerMutationVariables = Exact<{
  input: AddOrRemoveUserApplicationOwnerInput;
}>;

export type AssignApplicationOwnerMutation = { __typename?: "Mutation" } & {
  applicationMutation?: Maybe<
    { __typename?: "ApplicationMutation" } & {
      assignOwner: { __typename?: "Application" } & Pick<Application, "id">;
    }
  >;
};

export type RemoveApplicationOwnerMutationVariables = Exact<{
  input: AddOrRemoveUserApplicationOwnerInput;
}>;

export type RemoveApplicationOwnerMutation = { __typename?: "Mutation" } & {
  applicationMutation?: Maybe<
    { __typename?: "ApplicationMutation" } & {
      removeOwner: { __typename?: "Application" } & Pick<Application, "id">;
    }
  >;
};

export type CreateApplicationMutationVariables = Exact<{
  input: CreateApplicationInput;
}>;

export type CreateApplicationMutation = { __typename?: "Mutation" } & {
  applicationMutation?: Maybe<
    { __typename?: "ApplicationMutation" } & {
      createApplication?: Maybe<
        { __typename?: "Application" } & CommonApplicationPartsFragment
      >;
    }
  >;
};

export type DeleteApplicationMutationVariables = Exact<{
  id: Scalars["Guid"];
}>;

export type DeleteApplicationMutation = { __typename?: "Mutation" } & {
  applicationMutation?: Maybe<
    { __typename?: "ApplicationMutation" } & Pick<
      ApplicationMutation,
      "deleteApplication"
    >
  >;
};

export type UpdateApplicationMutationVariables = Exact<{
  input: UpdateApplicationInput;
}>;

export type UpdateApplicationMutation = { __typename?: "Mutation" } & {
  applicationMutation?: Maybe<
    { __typename?: "ApplicationMutation" } & {
      updateApplication?: Maybe<
        { __typename?: "Application" } & CommonApplicationPartsFragment
      >;
    }
  >;
};

export type GetApplicationQueryVariables = Exact<{
  id: Scalars["Guid"];
}>;

export type GetApplicationQuery = { __typename?: "BrugerstyringQuery" } & {
  application?: Maybe<
    { __typename?: "Application" } & Pick<
      Application,
      | "id"
      | "name"
      | "description"
      | "connectionId"
      | "coverImageUrl"
      | "logoUrl"
    > & {
        ownerOrganization?: Maybe<
          { __typename?: "Organization" } & Pick<
            Organization,
            "id" | "name" | "vat" | "logoUrl"
          >
        >;
        owners?: Maybe<
          Array<Maybe<{ __typename?: "User" } & Pick<User, "id" | "fullname">>>
        >;
      }
  >;
  brugerstyringApplication?: Maybe<
    { __typename?: "Application" } & Pick<Application, "id">
  >;
};

export type ApplicationByNameQueryVariables = Exact<{
  name: Scalars["String"];
}>;

export type ApplicationByNameQuery = { __typename?: "BrugerstyringQuery" } & {
  applicationByName?: Maybe<
    { __typename?: "Application" } & Pick<Application, "id" | "name">
  >;
};

export type GetapplicationOwnersQueryVariables = Exact<{
  input: Scalars["Guid"];
}>;

export type GetapplicationOwnersQuery = {
  __typename?: "BrugerstyringQuery";
} & {
  application?: Maybe<
    { __typename?: "Application" } & {
      owners?: Maybe<
        Array<
          Maybe<
            { __typename?: "User" } & Pick<
              User,
              "id" | "fullname" | "email" | "lastLogin"
            > & {
                organization?: Maybe<
                  { __typename?: "Organization" } & Pick<
                    Organization,
                    "id" | "name"
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type GetApplicationRolesQueryVariables = Exact<{
  ID: Scalars["Guid"];
}>;

export type GetApplicationRolesQuery = { __typename?: "BrugerstyringQuery" } & {
  application?: Maybe<
    { __typename?: "Application" } & {
      roles?: Maybe<
        { __typename?: "RoleGraphTypeConnection" } & {
          nodes?: Maybe<
            Array<
              Maybe<
                { __typename?: "Role" } & Pick<
                  Role,
                  "id" | "attribute" | "name" | "description"
                > & {
                    assignedUsers?: Maybe<
                      {
                        __typename?: "UserRoleAssignmentGraphTypeConnection";
                      } & Pick<
                        UserRoleAssignmentGraphTypeConnection,
                        "totalCount"
                      >
                    >;
                  }
              >
            >
          >;
        }
      >;
    }
  >;
};

export type GetApplicationUsersQueryVariables = Exact<{
  id: Scalars["Guid"];
  fromDate: Scalars["DateTime"];
  toDate: Scalars["DateTime"];
}>;

export type GetApplicationUsersQuery = { __typename?: "BrugerstyringQuery" } & {
  applicationLoginsReport?: Maybe<
    { __typename?: "ApplicationLoginsReport" } & {
      userLogins?: Maybe<
        Array<
          Maybe<
            { __typename?: "ApplicationLoginsReportItem" } & Pick<
              ApplicationLoginsReportItem,
              | "applicationName"
              | "userFullname"
              | "cvr"
              | "organizationName"
              | "noOfLogins"
            >
          >
        >
      >;
    }
  >;
};

export type GetApplicationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetApplicationsQuery = { __typename?: "BrugerstyringQuery" } & {
  applications?: Maybe<
    { __typename?: "ApplicationGraphTypeConnection" } & Pick<
      ApplicationGraphTypeConnection,
      "totalCount"
    > & {
        nodes?: Maybe<
          Array<
            Maybe<
              { __typename?: "Application" } & CommonApplicationPartsFragment
            >
          >
        >;
      }
  >;
};

export type CommonOrganizationPartsFragment = {
  __typename?: "Organization";
} & Pick<Organization, "id" | "logoUrl" | "name" | "vat"> & {
    users?: Maybe<
      { __typename?: "UserGraphTypeWithTotalOnlyList" } & Pick<
        UserGraphTypeWithTotalOnlyList,
        "totalCount"
      >
    >;
  };

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInput;
}>;

export type CreateOrganizationMutation = { __typename?: "Mutation" } & {
  organizationMutation?: Maybe<
    { __typename?: "OrganizationMutation" } & {
      createOrganization?: Maybe<
        { __typename?: "Organization" } & CommonOrganizationPartsFragment
      >;
    }
  >;
};

export type DeleteOrganizationMutationVariables = Exact<{
  id: Scalars["Guid"];
}>;

export type DeleteOrganizationMutation = { __typename?: "Mutation" } & {
  organizationMutation?: Maybe<
    { __typename?: "OrganizationMutation" } & Pick<
      OrganizationMutation,
      "deleteOrganization"
    >
  >;
};

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;

export type UpdateOrganizationMutation = { __typename?: "Mutation" } & {
  organizationMutation?: Maybe<
    { __typename?: "OrganizationMutation" } & {
      updateOrganization?: Maybe<
        { __typename?: "Organization" } & CommonOrganizationPartsFragment
      >;
    }
  >;
};

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars["Guid"];
}>;

export type GetOrganizationQuery = { __typename?: "BrugerstyringQuery" } & {
  organization?: Maybe<
    { __typename?: "Organization" } & Pick<
      Organization,
      "id" | "vat" | "name" | "description" | "logoUrl"
    > & {
        organizationContacts?: Maybe<
          Array<
            Maybe<
              { __typename?: "OrganizationContact" } & Pick<
                OrganizationContact,
                "id" | "name" | "phone" | "email"
              >
            >
          >
        >;
      }
  >;
  defaultOrganization?: Maybe<
    { __typename?: "Organization" } & Pick<Organization, "id">
  >;
};

export type GetOrganizationApplicationsQueryVariables = Exact<{
  id: Scalars["Guid"];
}>;

export type GetOrganizationApplicationsQuery = {
  __typename?: "BrugerstyringQuery";
} & {
  organization?: Maybe<
    { __typename?: "Organization" } & {
      assigneeRoles?: Maybe<
        Array<
          Maybe<
            { __typename?: "Role" } & Pick<Role, "id" | "name"> & {
                application?: Maybe<
                  { __typename?: "Application" } & Pick<
                    Application,
                    "id" | "name" | "description"
                  > & {
                      ownerOrganization?: Maybe<
                        { __typename?: "Organization" } & Pick<
                          Organization,
                          "id" | "name"
                        >
                      >;
                    }
                >;
              }
          >
        >
      >;
      applications?: Maybe<
        Array<
          Maybe<
            { __typename?: "Application" } & Pick<
              Application,
              "id" | "name" | "description"
            > & {
                ownerOrganization?: Maybe<
                  { __typename?: "Organization" } & Pick<
                    Organization,
                    "id" | "name"
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type GetOrganizationAsigneeRolesQueryVariables = Exact<{
  id: Scalars["Guid"];
}>;

export type GetOrganizationAsigneeRolesQuery = {
  __typename?: "BrugerstyringQuery";
} & {
  organization?: Maybe<
    { __typename?: "Organization" } & {
      assigneeRoles?: Maybe<
        Array<
          Maybe<
            { __typename?: "Role" } & Pick<
              Role,
              "id" | "name" | "description"
            > & {
                application?: Maybe<
                  { __typename?: "Application" } & Pick<
                    Application,
                    "id" | "name"
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type OrganizationByCvrQueryVariables = Exact<{
  cvr: Scalars["String"];
}>;

export type OrganizationByCvrQuery = { __typename?: "BrugerstyringQuery" } & {
  organizationByCvr?: Maybe<
    { __typename?: "Organization" } & Pick<Organization, "id" | "vat">
  >;
};

export type OrganizationByNameQueryVariables = Exact<{
  name: Scalars["String"];
}>;

export type OrganizationByNameQuery = { __typename?: "BrugerstyringQuery" } & {
  organizationByName?: Maybe<
    { __typename?: "Organization" } & Pick<Organization, "id" | "name">
  >;
};

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganizationsQuery = { __typename?: "BrugerstyringQuery" } & {
  organizations?: Maybe<
    { __typename?: "OrganizationGraphTypeConnection" } & Pick<
      OrganizationGraphTypeConnection,
      "totalCount"
    > & {
        nodes?: Maybe<
          Array<
            Maybe<
              { __typename?: "Organization" } & CommonOrganizationPartsFragment
            >
          >
        >;
      }
  >;
};

export type GetSelectableOrganizationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSelectableOrganizationsQuery = {
  __typename?: "BrugerstyringQuery";
} & {
  organizations?: Maybe<
    { __typename?: "OrganizationGraphTypeConnection" } & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: "Organization" } & Pick<
              Organization,
              "name" | "logoUrl" | "vat" | "id"
            >
          >
        >
      >;
    }
  >;
};

export type TokenSimulationQueryVariables = Exact<{
  applicationId: Scalars["Guid"];
  organizationId: Scalars["Guid"];
}>;

export type TokenSimulationQuery = { __typename?: "BrugerstyringQuery" } & {
  userSimulationToken?: Maybe<
    Array<
      Maybe<
        { __typename?: "KeyValuePair" } & Pick<KeyValuePair, "key" | "value">
      >
    >
  >;
};

export type CommonRolePartsFragment = { __typename?: "Role" } & Pick<
  Role,
  "id" | "attribute" | "name"
> & {
    application?: Maybe<
      { __typename?: "Application" } & Pick<Application, "id" | "name">
    >;
    assignedUsers?: Maybe<
      { __typename?: "UserRoleAssignmentGraphTypeConnection" } & Pick<
        UserRoleAssignmentGraphTypeConnection,
        "totalCount"
      >
    >;
  };

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInput;
}>;

export type CreateRoleMutation = { __typename?: "Mutation" } & {
  roleMutation?: Maybe<
    { __typename?: "RoleMutation" } & {
      createRole?: Maybe<{ __typename?: "Role" } & CommonRolePartsFragment>;
    }
  >;
};

export type UpdateRoleMutationVariables = Exact<{
  input: UpdateRoleInput;
}>;

export type UpdateRoleMutation = { __typename?: "Mutation" } & {
  roleMutation?: Maybe<
    { __typename?: "RoleMutation" } & {
      updateRole?: Maybe<{ __typename?: "Role" } & CommonRolePartsFragment>;
    }
  >;
};

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars["Guid"];
}>;

export type DeleteRoleMutation = { __typename?: "Mutation" } & {
  roleMutation?: Maybe<
    { __typename?: "RoleMutation" } & Pick<RoleMutation, "deleteRole">
  >;
};

export type RemoveRoleAssigneeMutationVariables = Exact<{
  input: AddOrRemoveRoleAssigneeInput;
}>;

export type RemoveRoleAssigneeMutation = { __typename?: "Mutation" } & {
  roleMutation?: Maybe<
    { __typename?: "RoleMutation" } & {
      removeRoleAssignee?: Maybe<{ __typename?: "Role" } & Pick<Role, "id">>;
    }
  >;
};

export type AddRoleAssigneeMutationVariables = Exact<{
  input: AddOrRemoveRoleAssigneeInput;
}>;

export type AddRoleAssigneeMutation = { __typename?: "Mutation" } & {
  roleMutation?: Maybe<
    { __typename?: "RoleMutation" } & {
      addRoleAssignee?: Maybe<{ __typename?: "Role" } & Pick<Role, "id">>;
    }
  >;
};

export type GetAssignableUsersQueryVariables = Exact<{
  filters?: Maybe<UserFiltersInput>;
  pageParams?: Maybe<PageParamsGraphType>;
  sortInfo?: Maybe<UserSortInfo>;
}>;

export type GetAssignableUsersQuery = { __typename?: "BrugerstyringQuery" } & {
  users?: Maybe<
    { __typename?: "UserGraphTypeList" } & Pick<
      UserGraphTypeList,
      "totalCount"
    > & {
        nodes?: Maybe<
          Array<Maybe<{ __typename?: "User" } & CommonUserPartsFragment>>
        >;
      }
  >;
};

export type GetRoleQueryVariables = Exact<{
  id: Scalars["Guid"];
}>;

export type GetRoleQuery = { __typename?: "BrugerstyringQuery" } & {
  role?: Maybe<
    { __typename?: "Role" } & Pick<
      Role,
      "id" | "name" | "attribute" | "description"
    > & {
        application?: Maybe<
          { __typename?: "Application" } & Pick<Application, "id" | "name">
        >;
      }
  >;
};

export type GetRoleAssigneesQueryVariables = Exact<{
  id: Scalars["Guid"];
}>;

export type GetRoleAssigneesQuery = { __typename?: "BrugerstyringQuery" } & {
  role?: Maybe<
    { __typename?: "Role" } & {
      assignees?: Maybe<
        Array<
          Maybe<
            { __typename?: "Organization" } & Pick<
              Organization,
              "id" | "name" | "vat" | "logoUrl"
            >
          >
        >
      >;
    }
  >;
};

export type GetRoleAssignedUsersQueryVariables = Exact<{
  id: Scalars["Guid"];
}>;

export type GetRoleAssignedUsersQuery = {
  __typename?: "BrugerstyringQuery";
} & {
  role?: Maybe<
    { __typename?: "Role" } & {
      assignedUsers?: Maybe<
        { __typename?: "UserRoleAssignmentGraphTypeConnection" } & {
          nodes?: Maybe<
            Array<
              Maybe<
                { __typename?: "UserRoleAssignment" } & Pick<
                  UserRoleAssignment,
                  "lastUse" | "expirationDate"
                > & {
                    organization?: Maybe<
                      { __typename?: "Organization" } & Pick<
                        Organization,
                        "id" | "name" | "vat"
                      >
                    >;
                    user?: Maybe<
                      { __typename?: "User" } & Pick<
                        User,
                        "id" | "fullname" | "email" | "lastLogin"
                      > & {
                          organization?: Maybe<
                            { __typename?: "Organization" } & Pick<
                              Organization,
                              "id" | "name" | "vat"
                            >
                          >;
                        }
                    >;
                  }
              >
            >
          >;
        }
      >;
    }
  >;
};

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRolesQuery = { __typename?: "BrugerstyringQuery" } & {
  roles?: Maybe<
    { __typename?: "RoleGraphTypeConnection" } & Pick<
      RoleGraphTypeConnection,
      "totalCount"
    > & {
        nodes?: Maybe<
          Array<Maybe<{ __typename?: "Role" } & CommonRolePartsFragment>>
        >;
      }
  >;
};

export type RoleByAttributeQueryVariables = Exact<{
  attribute: Scalars["String"];
}>;

export type RoleByAttributeQuery = { __typename?: "BrugerstyringQuery" } & {
  roleByAttribute?: Maybe<
    { __typename?: "Role" } & Pick<Role, "id" | "attribute" | "name">
  >;
};

export type ChangeEmailMutationVariables = Exact<{
  input: UpdateUserProfileInput;
}>;

export type ChangeEmailMutation = { __typename?: "Mutation" } & {
  userMutation?: Maybe<
    { __typename?: "UserMutation" } & Pick<UserMutation, "updateUserProfile">
  >;
};

export type ConfirmEmailMutationVariables = Exact<{
  input: ConfirmEmailVerificationInput;
}>;

export type ConfirmEmailMutation = { __typename?: "Mutation" } & {
  userMutation?: Maybe<
    { __typename?: "UserMutation" } & {
      confirmEmailVerification: {
        __typename?: "EmailVerificationStatusEnum";
      } & Pick<EmailVerificationStatusEnum, "status">;
    }
  >;
};

export type ConfirmLinkUserMutationVariables = Exact<{
  input: ConfirmLinkNemLoginIdInput;
}>;

export type ConfirmLinkUserMutation = { __typename?: "Mutation" } & {
  userMutation?: Maybe<
    { __typename?: "UserMutation" } & {
      confirmLinkNemLoginId: {
        __typename?: "EmailVerificationStatusEnum";
      } & Pick<EmailVerificationStatusEnum, "status">;
    }
  >;
};

export type ResendEmailMutationVariables = Exact<{
  input: Scalars["Guid"];
}>;

export type ResendEmailMutation = { __typename?: "Mutation" } & {
  userMutation?: Maybe<
    { __typename?: "UserMutation" } & Pick<
      UserMutation,
      "resendEmailVerification"
    >
  >;
};

export type CreateTestUserMutationVariables = Exact<{
  input: CreateTestUserInput;
}>;

export type CreateTestUserMutation = { __typename?: "Mutation" } & {
  userMutation?: Maybe<
    { __typename?: "UserMutation" } & {
      createTestUser: { __typename?: "User" } & CommonUserPartsFragment;
    }
  >;
};

export type CommonUserPartsFragment = { __typename?: "User" } & Pick<
  User,
  "id" | "fullname" | "email" | "lastLogin"
> & {
    organization?: Maybe<
      { __typename?: "Organization" } & Pick<
        Organization,
        "id" | "name" | "vat"
      >
    >;
  };

export type CommonUserRoleAssignmentPartsFragment = { __typename?: "User" } & {
  roleAssignments?: Maybe<
    Array<
      Maybe<
        { __typename?: "UserRoleAssignment" } & Pick<
          UserRoleAssignment,
          "expirationDate" | "lastUse"
        > & {
            role?: Maybe<
              { __typename?: "Role" } & Pick<
                Role,
                "id" | "name" | "attribute" | "description"
              > & {
                  application?: Maybe<
                    { __typename?: "Application" } & Pick<
                      Application,
                      "id" | "name"
                    > & {
                        ownerOrganization?: Maybe<
                          { __typename?: "Organization" } & Pick<
                            Organization,
                            "id"
                          >
                        >;
                      }
                  >;
                }
            >;
            organization?: Maybe<
              { __typename?: "Organization" } & Pick<
                Organization,
                "id" | "name" | "logoUrl" | "vat"
              >
            >;
          }
      >
    >
  >;
};

export type RemoveApplicationAdminAssignmentMutationVariables = Exact<{
  input: AddOrRemoveUserApplicationAssignmentInput;
}>;

export type RemoveApplicationAdminAssignmentMutation = {
  __typename?: "Mutation";
} & {
  userMutation?: Maybe<
    { __typename?: "UserMutation" } & {
      removeApplication: { __typename?: "User" } & Pick<User, "id">;
    }
  >;
};

export type AssignApplicationAdminAssignmentMutationVariables = Exact<{
  input: AddOrRemoveUserApplicationAssignmentInput;
}>;

export type AssignApplicationAdminAssignmentMutation = {
  __typename?: "Mutation";
} & {
  userMutation?: Maybe<
    { __typename?: "UserMutation" } & {
      assignApplication: { __typename?: "User" } & Pick<User, "id">;
    }
  >;
};

export type AssignRoleToUserMutationVariables = Exact<{
  input: AssignUserRoleInput;
}>;

export type AssignRoleToUserMutation = { __typename?: "Mutation" } & {
  userMutation?: Maybe<
    { __typename?: "UserMutation" } & {
      assignRole: { __typename?: "User" } & Pick<User, "id">;
    }
  >;
};

export type RemoveRoleFromUserMutationVariables = Exact<{
  input: RemoveUserRoleInput;
}>;

export type RemoveRoleFromUserMutation = { __typename?: "Mutation" } & {
  userMutation?: Maybe<
    { __typename?: "UserMutation" } & {
      removeRole: { __typename?: "User" } & Pick<User, "id"> & {
          roleAssignments?: Maybe<
            Array<
              Maybe<
                { __typename?: "UserRoleAssignment" } & {
                  role?: Maybe<{ __typename?: "Role" } & Pick<Role, "id">>;
                }
              >
            >
          >;
        };
    }
  >;
};

export type UpdateRoleAssignmentMutationVariables = Exact<{
  input: UpdateUserRoleAssignmentExpiryDateInput;
}>;

export type UpdateRoleAssignmentMutation = { __typename?: "Mutation" } & {
  userMutation?: Maybe<
    { __typename?: "UserMutation" } & {
      updateUserRoleAssignmentExpiryDate: {
        __typename?: "UserRoleAssignment";
      } & {
        user?: Maybe<{ __typename?: "User" } & Pick<User, "id">>;
        role?: Maybe<{ __typename?: "Role" } & Pick<Role, "id">>;
      };
    }
  >;
};

export type AssignRoleOnBehalfOfMutationVariables = Exact<{
  input: AssignUserRoleOnBehalfOfInput;
}>;

export type AssignRoleOnBehalfOfMutation = { __typename?: "Mutation" } & {
  userMutation?: Maybe<
    { __typename?: "UserMutation" } & {
      assignRoleOnBehalfOf: { __typename?: "User" } & CommonUserPartsFragment;
    }
  >;
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars["Guid"];
}>;

export type DeleteUserMutation = { __typename?: "Mutation" } & {
  userMutation?: Maybe<
    { __typename?: "UserMutation" } & Pick<UserMutation, "deleteUser">
  >;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = { __typename?: "Mutation" } & {
  userMutation?: Maybe<
    { __typename?: "UserMutation" } & {
      updateUser: { __typename?: "User" } & CommonUserPartsFragment;
    }
  >;
};

export type GetUserQueryVariables = Exact<{
  id: Scalars["Guid"];
}>;

export type GetUserQuery = { __typename?: "BrugerstyringQuery" } & {
  user?: Maybe<
    { __typename?: "User" } & Pick<
      User,
      | "id"
      | "firstname"
      | "lastname"
      | "fullname"
      | "email"
      | "description"
      | "nemloginId"
      | "emailVerificationDate"
    > & {
        organization?: Maybe<
          { __typename?: "Organization" } & Pick<
            Organization,
            "id" | "vat" | "name" | "logoUrl"
          >
        >;
        roleAssignments?: Maybe<
          Array<
            Maybe<
              { __typename?: "UserRoleAssignment" } & {
                role?: Maybe<
                  { __typename?: "Role" } & Pick<Role, "id" | "attribute">
                >;
              }
            >
          >
        >;
        upstreamIdpIds?: Maybe<
          Array<
            Maybe<
              { __typename?: "UpstreamIdpId" } & Pick<
                UpstreamIdpId,
                "upstreamIdpId" | "upstreamIdpUserId"
              >
            >
          >
        >;
      }
  >;
};

export type GetUserApplicationsQueryVariables = Exact<{
  id: Scalars["Guid"];
}>;

export type GetUserApplicationsQuery = { __typename?: "BrugerstyringQuery" } & {
  user?: Maybe<
    { __typename?: "User" } & {
      applicationAssignments?: Maybe<
        Array<
          Maybe<
            { __typename?: "Application" } & Pick<
              Application,
              "id" | "name"
            > & {
                roles?: Maybe<
                  { __typename?: "RoleGraphTypeConnection" } & Pick<
                    RoleGraphTypeConnection,
                    "totalCount"
                  >
                >;
                ownerOrganization?: Maybe<
                  { __typename?: "Organization" } & Pick<
                    Organization,
                    "id" | "name"
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type GetUserByEmailQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type GetUserByEmailQuery = { __typename?: "BrugerstyringQuery" } & {
  userByEmail?: Maybe<
    { __typename?: "User" } & Pick<User, "id"> & {
        organization?: Maybe<
          { __typename?: "Organization" } & Pick<
            Organization,
            "id" | "name" | "logoUrl" | "vat"
          >
        >;
      }
  >;
};

export type GetUserRoleAssignmentsQueryVariables = Exact<{
  id: Scalars["Guid"];
}>;

export type GetUserRoleAssignmentsQuery = {
  __typename?: "BrugerstyringQuery";
} & {
  user?: Maybe<{ __typename?: "User" } & CommonUserRoleAssignmentPartsFragment>;
};

export type GetUserRoleAssignmentsByEmailQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type GetUserRoleAssignmentsByEmailQuery = {
  __typename?: "BrugerstyringQuery";
} & {
  user?: Maybe<{ __typename?: "User" } & CommonUserRoleAssignmentPartsFragment>;
};

export type GetUsersQueryVariables = Exact<{
  filters?: Maybe<UserFiltersInput>;
  pageParams?: Maybe<PageParamsGraphType>;
  sortInfo?: Maybe<UserSortInfo>;
}>;

export type GetUsersQuery = { __typename?: "BrugerstyringQuery" } & {
  users?: Maybe<
    { __typename?: "UserGraphTypeList" } & Pick<
      UserGraphTypeList,
      "totalCount"
    > & {
        nodes?: Maybe<
          Array<
            Maybe<
              { __typename?: "User" } & Pick<User, "nemloginId"> &
                CommonUserPartsFragment
            >
          >
        >;
      }
  >;
};

export const CommonApplicationPartsFragmentDoc = gql`
  fragment CommonApplicationParts on Application {
    id
    name
    ownerOrganization {
      id
      name
      logoUrl
    }
    roles {
      totalCount
    }
  }
`;
export const CommonOrganizationPartsFragmentDoc = gql`
  fragment CommonOrganizationParts on Organization {
    id
    logoUrl
    name
    vat
    users {
      totalCount
    }
  }
`;
export const CommonRolePartsFragmentDoc = gql`
  fragment CommonRoleParts on Role {
    id
    attribute
    name
    application {
      id
      name
    }
    assignedUsers {
      totalCount
    }
  }
`;
export const CommonUserPartsFragmentDoc = gql`
  fragment CommonUserParts on User {
    id
    fullname
    organization {
      id
      name
      vat
    }
    email
    lastLogin
  }
`;
export const CommonUserRoleAssignmentPartsFragmentDoc = gql`
  fragment CommonUserRoleAssignmentParts on User {
    roleAssignments {
      role {
        id
        name
        attribute
        description
        application {
          id
          name
          ownerOrganization {
            id
          }
        }
      }
      organization {
        id
        name
        logoUrl
        vat
      }
      expirationDate
      lastUse
    }
  }
`;
export const GetHistoriesDocument = gql`
  query GetHistories($id: Guid!, $pageParams: PageParamsGraphType!) {
    histories(id: $id, pageParams: $pageParams) {
      totalCount
      nodes {
        userName
        user {
          id
          fullname
          organization {
            id
            name
          }
        }
        datetime
        action
        details {
          property
          from
          to
          fromRefId
          toRefId
        }
      }
    }
  }
`;

/**
 * __useGetHistoriesQuery__
 *
 * To run a query within a Vue component, call `useGetHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHistoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetHistoriesQuery({
 *   id: // value for 'id'
 *   pageParams: // value for 'pageParams'
 * });
 */
export function useGetHistoriesQuery(
  variables:
    | GetHistoriesQueryVariables
    | VueCompositionApi.Ref<GetHistoriesQueryVariables>
    | ReactiveFunction<GetHistoriesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetHistoriesQuery,
        GetHistoriesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetHistoriesQuery,
          GetHistoriesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetHistoriesQuery,
          GetHistoriesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetHistoriesQuery,
    GetHistoriesQueryVariables
  >(GetHistoriesDocument, variables, options);
}
export function useGetHistoriesLazyQuery(
  variables:
    | GetHistoriesQueryVariables
    | VueCompositionApi.Ref<GetHistoriesQueryVariables>
    | ReactiveFunction<GetHistoriesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetHistoriesQuery,
        GetHistoriesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetHistoriesQuery,
          GetHistoriesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetHistoriesQuery,
          GetHistoriesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetHistoriesQuery,
    GetHistoriesQueryVariables
  >(GetHistoriesDocument, variables, options);
}
export type GetHistoriesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetHistoriesQuery,
    GetHistoriesQueryVariables
  >;
export const GetSelectableApplicationsDocument = gql`
  query GetSelectableApplications {
    applications {
      nodes {
        id
        name
        ownerOrganization {
          id
          name
          vat
        }
      }
    }
  }
`;

/**
 * __useGetSelectableApplicationsQuery__
 *
 * To run a query within a Vue component, call `useGetSelectableApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectableApplicationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSelectableApplicationsQuery();
 */
export function useGetSelectableApplicationsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetSelectableApplicationsQuery,
        GetSelectableApplicationsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetSelectableApplicationsQuery,
          GetSelectableApplicationsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetSelectableApplicationsQuery,
          GetSelectableApplicationsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetSelectableApplicationsQuery,
    GetSelectableApplicationsQueryVariables
  >(GetSelectableApplicationsDocument, {}, options);
}
export function useGetSelectableApplicationsLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetSelectableApplicationsQuery,
        GetSelectableApplicationsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetSelectableApplicationsQuery,
          GetSelectableApplicationsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetSelectableApplicationsQuery,
          GetSelectableApplicationsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetSelectableApplicationsQuery,
    GetSelectableApplicationsQueryVariables
  >(GetSelectableApplicationsDocument, {}, options);
}
export type GetSelectableApplicationsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetSelectableApplicationsQuery,
    GetSelectableApplicationsQueryVariables
  >;
export const GetSelectableOrganizationDocument = gql`
  query GetSelectableOrganization {
    organizations {
      nodes {
        id
        name
        vat
      }
    }
  }
`;

/**
 * __useGetSelectableOrganizationQuery__
 *
 * To run a query within a Vue component, call `useGetSelectableOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectableOrganizationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSelectableOrganizationQuery();
 */
export function useGetSelectableOrganizationQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetSelectableOrganizationQuery,
        GetSelectableOrganizationQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetSelectableOrganizationQuery,
          GetSelectableOrganizationQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetSelectableOrganizationQuery,
          GetSelectableOrganizationQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetSelectableOrganizationQuery,
    GetSelectableOrganizationQueryVariables
  >(GetSelectableOrganizationDocument, {}, options);
}
export function useGetSelectableOrganizationLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetSelectableOrganizationQuery,
        GetSelectableOrganizationQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetSelectableOrganizationQuery,
          GetSelectableOrganizationQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetSelectableOrganizationQuery,
          GetSelectableOrganizationQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetSelectableOrganizationQuery,
    GetSelectableOrganizationQueryVariables
  >(GetSelectableOrganizationDocument, {}, options);
}
export type GetSelectableOrganizationQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetSelectableOrganizationQuery,
    GetSelectableOrganizationQueryVariables
  >;
export const GetSelectableRolesDocument = gql`
  query GetSelectableRoles {
    roles {
      nodes {
        id
        attribute
        name
        description
        application {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetSelectableRolesQuery__
 *
 * To run a query within a Vue component, call `useGetSelectableRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectableRolesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSelectableRolesQuery();
 */
export function useGetSelectableRolesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetSelectableRolesQuery,
        GetSelectableRolesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetSelectableRolesQuery,
          GetSelectableRolesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetSelectableRolesQuery,
          GetSelectableRolesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetSelectableRolesQuery,
    GetSelectableRolesQueryVariables
  >(GetSelectableRolesDocument, {}, options);
}
export function useGetSelectableRolesLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetSelectableRolesQuery,
        GetSelectableRolesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetSelectableRolesQuery,
          GetSelectableRolesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetSelectableRolesQuery,
          GetSelectableRolesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetSelectableRolesQuery,
    GetSelectableRolesQueryVariables
  >(GetSelectableRolesDocument, {}, options);
}
export type GetSelectableRolesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetSelectableRolesQuery,
    GetSelectableRolesQueryVariables
  >;
export const GetUserProfileDocument = gql`
  query GetUserProfile {
    profile {
      oboOrganization {
        name
        id
        vat
        applications {
          id
          name
        }
        assigneeRoles {
          id
          name
          application {
            id
          }
        }
      }
      user {
        id
        firstname
        lastname
        fullname
        description
        emailVerificationDate
        roleAssignments {
          organization {
            id
            name
          }
          role {
            id
            application {
              id
              name
              ownerOrganization {
                id
                name
              }
            }
            attribute
            name
          }
        }
        email
        nemloginId
        isLinkedNemLogIn
        organization {
          name
          id
          vat
        }
        applicationAssignments {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a Vue component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUserProfileQuery();
 */
export function useGetUserProfileQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetUserProfileQuery,
        GetUserProfileQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetUserProfileQuery,
          GetUserProfileQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetUserProfileQuery,
          GetUserProfileQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >(GetUserProfileDocument, {}, options);
}
export function useGetUserProfileLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetUserProfileQuery,
        GetUserProfileQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetUserProfileQuery,
          GetUserProfileQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetUserProfileQuery,
          GetUserProfileQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >(GetUserProfileDocument, {}, options);
}
export type GetUserProfileQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >;
export const SelectApplicationQueryDocument = gql`
  query SelectApplicationQuery {
    applications {
      nodes {
        name
        id
      }
    }
  }
`;

/**
 * __useSelectApplicationQueryQuery__
 *
 * To run a query within a Vue component, call `useSelectApplicationQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectApplicationQueryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSelectApplicationQueryQuery();
 */
export function useSelectApplicationQueryQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        SelectApplicationQueryQuery,
        SelectApplicationQueryQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          SelectApplicationQueryQuery,
          SelectApplicationQueryQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          SelectApplicationQueryQuery,
          SelectApplicationQueryQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    SelectApplicationQueryQuery,
    SelectApplicationQueryQueryVariables
  >(SelectApplicationQueryDocument, {}, options);
}
export function useSelectApplicationQueryLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        SelectApplicationQueryQuery,
        SelectApplicationQueryQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          SelectApplicationQueryQuery,
          SelectApplicationQueryQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          SelectApplicationQueryQuery,
          SelectApplicationQueryQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    SelectApplicationQueryQuery,
    SelectApplicationQueryQueryVariables
  >(SelectApplicationQueryDocument, {}, options);
}
export type SelectApplicationQueryQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    SelectApplicationQueryQuery,
    SelectApplicationQueryQueryVariables
  >;
export const AssignApplicationOwnerDocument = gql`
  mutation AssignApplicationOwner(
    $input: AddOrRemoveUserApplicationOwnerInput!
  ) {
    applicationMutation {
      assignOwner(input: $input) {
        id
      }
    }
  }
`;

/**
 * __useAssignApplicationOwnerMutation__
 *
 * To run a mutation, you first call `useAssignApplicationOwnerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAssignApplicationOwnerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAssignApplicationOwnerMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useAssignApplicationOwnerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AssignApplicationOwnerMutation,
        AssignApplicationOwnerMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AssignApplicationOwnerMutation,
          AssignApplicationOwnerMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AssignApplicationOwnerMutation,
    AssignApplicationOwnerMutationVariables
  >(AssignApplicationOwnerDocument, options);
}
export type AssignApplicationOwnerMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AssignApplicationOwnerMutation,
    AssignApplicationOwnerMutationVariables
  >;
export const RemoveApplicationOwnerDocument = gql`
  mutation RemoveApplicationOwner(
    $input: AddOrRemoveUserApplicationOwnerInput!
  ) {
    applicationMutation {
      removeOwner(input: $input) {
        id
      }
    }
  }
`;

/**
 * __useRemoveApplicationOwnerMutation__
 *
 * To run a mutation, you first call `useRemoveApplicationOwnerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApplicationOwnerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveApplicationOwnerMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveApplicationOwnerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveApplicationOwnerMutation,
        RemoveApplicationOwnerMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveApplicationOwnerMutation,
          RemoveApplicationOwnerMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveApplicationOwnerMutation,
    RemoveApplicationOwnerMutationVariables
  >(RemoveApplicationOwnerDocument, options);
}
export type RemoveApplicationOwnerMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveApplicationOwnerMutation,
    RemoveApplicationOwnerMutationVariables
  >;
export const CreateApplicationDocument = gql`
  mutation CreateApplication($input: CreateApplicationInput!) {
    applicationMutation {
      createApplication(input: $input) {
        ...CommonApplicationParts
      }
    }
  }
  ${CommonApplicationPartsFragmentDoc}
`;

/**
 * __useCreateApplicationMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateApplicationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateApplicationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateApplicationMutation,
        CreateApplicationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateApplicationMutation,
          CreateApplicationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateApplicationMutation,
    CreateApplicationMutationVariables
  >(CreateApplicationDocument, options);
}
export type CreateApplicationMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateApplicationMutation,
    CreateApplicationMutationVariables
  >;
export const DeleteApplicationDocument = gql`
  mutation DeleteApplication($id: Guid!) {
    applicationMutation {
      deleteApplication(applicationId: $id)
    }
  }
`;

/**
 * __useDeleteApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteApplicationMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApplicationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteApplicationMutation,
        DeleteApplicationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteApplicationMutation,
          DeleteApplicationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteApplicationMutation,
    DeleteApplicationMutationVariables
  >(DeleteApplicationDocument, options);
}
export type DeleteApplicationMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteApplicationMutation,
    DeleteApplicationMutationVariables
  >;
export const UpdateApplicationDocument = gql`
  mutation UpdateApplication($input: UpdateApplicationInput!) {
    applicationMutation {
      updateApplication(input: $input) {
        ...CommonApplicationParts
      }
    }
  }
  ${CommonApplicationPartsFragmentDoc}
`;

/**
 * __useUpdateApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateApplicationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateApplicationMutation,
        UpdateApplicationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateApplicationMutation,
          UpdateApplicationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateApplicationMutation,
    UpdateApplicationMutationVariables
  >(UpdateApplicationDocument, options);
}
export type UpdateApplicationMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateApplicationMutation,
    UpdateApplicationMutationVariables
  >;
export const GetApplicationDocument = gql`
  query GetApplication($id: Guid!) {
    application(id: $id) {
      id
      name
      description
      connectionId
      coverImageUrl
      logoUrl
      ownerOrganization {
        id
        name
        vat
        logoUrl
      }
      owners {
        id
        fullname
      }
    }
    brugerstyringApplication {
      id
    }
  }
`;

/**
 * __useGetApplicationQuery__
 *
 * To run a query within a Vue component, call `useGetApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetApplicationQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetApplicationQuery(
  variables:
    | GetApplicationQueryVariables
    | VueCompositionApi.Ref<GetApplicationQueryVariables>
    | ReactiveFunction<GetApplicationQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetApplicationQuery,
        GetApplicationQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetApplicationQuery,
          GetApplicationQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetApplicationQuery,
          GetApplicationQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetApplicationQuery,
    GetApplicationQueryVariables
  >(GetApplicationDocument, variables, options);
}
export function useGetApplicationLazyQuery(
  variables:
    | GetApplicationQueryVariables
    | VueCompositionApi.Ref<GetApplicationQueryVariables>
    | ReactiveFunction<GetApplicationQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetApplicationQuery,
        GetApplicationQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetApplicationQuery,
          GetApplicationQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetApplicationQuery,
          GetApplicationQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetApplicationQuery,
    GetApplicationQueryVariables
  >(GetApplicationDocument, variables, options);
}
export type GetApplicationQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetApplicationQuery,
    GetApplicationQueryVariables
  >;
export const ApplicationByNameDocument = gql`
  query ApplicationByName($name: String!) {
    applicationByName(name: $name) {
      id
      name
    }
  }
`;

/**
 * __useApplicationByNameQuery__
 *
 * To run a query within a Vue component, call `useApplicationByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationByNameQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useApplicationByNameQuery({
 *   name: // value for 'name'
 * });
 */
export function useApplicationByNameQuery(
  variables:
    | ApplicationByNameQueryVariables
    | VueCompositionApi.Ref<ApplicationByNameQueryVariables>
    | ReactiveFunction<ApplicationByNameQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        ApplicationByNameQuery,
        ApplicationByNameQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ApplicationByNameQuery,
          ApplicationByNameQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ApplicationByNameQuery,
          ApplicationByNameQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    ApplicationByNameQuery,
    ApplicationByNameQueryVariables
  >(ApplicationByNameDocument, variables, options);
}
export function useApplicationByNameLazyQuery(
  variables:
    | ApplicationByNameQueryVariables
    | VueCompositionApi.Ref<ApplicationByNameQueryVariables>
    | ReactiveFunction<ApplicationByNameQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        ApplicationByNameQuery,
        ApplicationByNameQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          ApplicationByNameQuery,
          ApplicationByNameQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          ApplicationByNameQuery,
          ApplicationByNameQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    ApplicationByNameQuery,
    ApplicationByNameQueryVariables
  >(ApplicationByNameDocument, variables, options);
}
export type ApplicationByNameQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    ApplicationByNameQuery,
    ApplicationByNameQueryVariables
  >;
export const GetapplicationOwnersDocument = gql`
  query GetapplicationOwners($input: Guid!) {
    application(id: $input) {
      owners {
        id
        fullname
        email
        lastLogin
        organization {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetapplicationOwnersQuery__
 *
 * To run a query within a Vue component, call `useGetapplicationOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetapplicationOwnersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetapplicationOwnersQuery({
 *   input: // value for 'input'
 * });
 */
export function useGetapplicationOwnersQuery(
  variables:
    | GetapplicationOwnersQueryVariables
    | VueCompositionApi.Ref<GetapplicationOwnersQueryVariables>
    | ReactiveFunction<GetapplicationOwnersQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetapplicationOwnersQuery,
        GetapplicationOwnersQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetapplicationOwnersQuery,
          GetapplicationOwnersQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetapplicationOwnersQuery,
          GetapplicationOwnersQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetapplicationOwnersQuery,
    GetapplicationOwnersQueryVariables
  >(GetapplicationOwnersDocument, variables, options);
}
export function useGetapplicationOwnersLazyQuery(
  variables:
    | GetapplicationOwnersQueryVariables
    | VueCompositionApi.Ref<GetapplicationOwnersQueryVariables>
    | ReactiveFunction<GetapplicationOwnersQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetapplicationOwnersQuery,
        GetapplicationOwnersQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetapplicationOwnersQuery,
          GetapplicationOwnersQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetapplicationOwnersQuery,
          GetapplicationOwnersQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetapplicationOwnersQuery,
    GetapplicationOwnersQueryVariables
  >(GetapplicationOwnersDocument, variables, options);
}
export type GetapplicationOwnersQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetapplicationOwnersQuery,
    GetapplicationOwnersQueryVariables
  >;
export const GetApplicationRolesDocument = gql`
  query GetApplicationRoles($ID: Guid!) {
    application(id: $ID) {
      roles {
        nodes {
          id
          attribute
          name
          description
          assignedUsers {
            totalCount
          }
        }
      }
    }
  }
`;

/**
 * __useGetApplicationRolesQuery__
 *
 * To run a query within a Vue component, call `useGetApplicationRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationRolesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetApplicationRolesQuery({
 *   ID: // value for 'ID'
 * });
 */
export function useGetApplicationRolesQuery(
  variables:
    | GetApplicationRolesQueryVariables
    | VueCompositionApi.Ref<GetApplicationRolesQueryVariables>
    | ReactiveFunction<GetApplicationRolesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetApplicationRolesQuery,
        GetApplicationRolesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetApplicationRolesQuery,
          GetApplicationRolesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetApplicationRolesQuery,
          GetApplicationRolesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetApplicationRolesQuery,
    GetApplicationRolesQueryVariables
  >(GetApplicationRolesDocument, variables, options);
}
export function useGetApplicationRolesLazyQuery(
  variables:
    | GetApplicationRolesQueryVariables
    | VueCompositionApi.Ref<GetApplicationRolesQueryVariables>
    | ReactiveFunction<GetApplicationRolesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetApplicationRolesQuery,
        GetApplicationRolesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetApplicationRolesQuery,
          GetApplicationRolesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetApplicationRolesQuery,
          GetApplicationRolesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetApplicationRolesQuery,
    GetApplicationRolesQueryVariables
  >(GetApplicationRolesDocument, variables, options);
}
export type GetApplicationRolesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetApplicationRolesQuery,
    GetApplicationRolesQueryVariables
  >;
export const GetApplicationUsersDocument = gql`
  query GetApplicationUsers(
    $id: Guid!
    $fromDate: DateTime!
    $toDate: DateTime!
  ) {
    applicationLoginsReport(id: $id, fromDate: $fromDate, toDate: $toDate) {
      userLogins {
        applicationName
        userFullname
        cvr
        organizationName
        noOfLogins
      }
    }
  }
`;

/**
 * __useGetApplicationUsersQuery__
 *
 * To run a query within a Vue component, call `useGetApplicationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationUsersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetApplicationUsersQuery({
 *   id: // value for 'id'
 *   fromDate: // value for 'fromDate'
 *   toDate: // value for 'toDate'
 * });
 */
export function useGetApplicationUsersQuery(
  variables:
    | GetApplicationUsersQueryVariables
    | VueCompositionApi.Ref<GetApplicationUsersQueryVariables>
    | ReactiveFunction<GetApplicationUsersQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetApplicationUsersQuery,
        GetApplicationUsersQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetApplicationUsersQuery,
          GetApplicationUsersQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetApplicationUsersQuery,
          GetApplicationUsersQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetApplicationUsersQuery,
    GetApplicationUsersQueryVariables
  >(GetApplicationUsersDocument, variables, options);
}
export function useGetApplicationUsersLazyQuery(
  variables:
    | GetApplicationUsersQueryVariables
    | VueCompositionApi.Ref<GetApplicationUsersQueryVariables>
    | ReactiveFunction<GetApplicationUsersQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetApplicationUsersQuery,
        GetApplicationUsersQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetApplicationUsersQuery,
          GetApplicationUsersQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetApplicationUsersQuery,
          GetApplicationUsersQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetApplicationUsersQuery,
    GetApplicationUsersQueryVariables
  >(GetApplicationUsersDocument, variables, options);
}
export type GetApplicationUsersQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetApplicationUsersQuery,
    GetApplicationUsersQueryVariables
  >;
export const GetApplicationsDocument = gql`
  query GetApplications {
    applications {
      totalCount
      nodes {
        ...CommonApplicationParts
      }
    }
  }
  ${CommonApplicationPartsFragmentDoc}
`;

/**
 * __useGetApplicationsQuery__
 *
 * To run a query within a Vue component, call `useGetApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetApplicationsQuery();
 */
export function useGetApplicationsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetApplicationsQuery,
        GetApplicationsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetApplicationsQuery,
          GetApplicationsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetApplicationsQuery,
          GetApplicationsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetApplicationsQuery,
    GetApplicationsQueryVariables
  >(GetApplicationsDocument, {}, options);
}
export function useGetApplicationsLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetApplicationsQuery,
        GetApplicationsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetApplicationsQuery,
          GetApplicationsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetApplicationsQuery,
          GetApplicationsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetApplicationsQuery,
    GetApplicationsQueryVariables
  >(GetApplicationsDocument, {}, options);
}
export type GetApplicationsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetApplicationsQuery,
    GetApplicationsQueryVariables
  >;
export const CreateOrganizationDocument = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    organizationMutation {
      createOrganization(input: $input) {
        ...CommonOrganizationParts
      }
    }
  }
  ${CommonOrganizationPartsFragmentDoc}
`;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOrganizationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOrganizationMutation,
        CreateOrganizationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOrganizationMutation,
          CreateOrganizationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(CreateOrganizationDocument, options);
}
export type CreateOrganizationMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >;
export const DeleteOrganizationDocument = gql`
  mutation DeleteOrganization($id: Guid!) {
    organizationMutation {
      deleteOrganization(organizationId: $id)
    }
  }
`;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOrganizationMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOrganizationMutation,
        DeleteOrganizationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOrganizationMutation,
          DeleteOrganizationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >(DeleteOrganizationDocument, options);
}
export type DeleteOrganizationMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >;
export const UpdateOrganizationDocument = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    organizationMutation {
      updateOrganization(input: $input) {
        ...CommonOrganizationParts
      }
    }
  }
  ${CommonOrganizationPartsFragmentDoc}
`;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOrganizationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOrganizationMutation,
        UpdateOrganizationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOrganizationMutation,
          UpdateOrganizationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >(UpdateOrganizationDocument, options);
}
export type UpdateOrganizationMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >;
export const GetOrganizationDocument = gql`
  query GetOrganization($id: Guid!) {
    organization(id: $id) {
      id
      vat
      name
      description
      logoUrl
      organizationContacts {
        id
        name
        phone
        email
      }
    }
    defaultOrganization {
      id
    }
  }
`;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a Vue component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOrganizationQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOrganizationQuery(
  variables:
    | GetOrganizationQueryVariables
    | VueCompositionApi.Ref<GetOrganizationQueryVariables>
    | ReactiveFunction<GetOrganizationQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetOrganizationQuery,
        GetOrganizationQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationQuery,
          GetOrganizationQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationQuery,
          GetOrganizationQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >(GetOrganizationDocument, variables, options);
}
export function useGetOrganizationLazyQuery(
  variables:
    | GetOrganizationQueryVariables
    | VueCompositionApi.Ref<GetOrganizationQueryVariables>
    | ReactiveFunction<GetOrganizationQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetOrganizationQuery,
        GetOrganizationQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationQuery,
          GetOrganizationQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationQuery,
          GetOrganizationQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >(GetOrganizationDocument, variables, options);
}
export type GetOrganizationQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >;
export const GetOrganizationApplicationsDocument = gql`
  query GetOrganizationApplications($id: Guid!) {
    organization(id: $id) {
      assigneeRoles {
        id
        name
        application {
          id
          name
          description
          ownerOrganization {
            id
            name
          }
        }
      }
      applications {
        id
        name
        description
        ownerOrganization {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetOrganizationApplicationsQuery__
 *
 * To run a query within a Vue component, call `useGetOrganizationApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationApplicationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOrganizationApplicationsQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOrganizationApplicationsQuery(
  variables:
    | GetOrganizationApplicationsQueryVariables
    | VueCompositionApi.Ref<GetOrganizationApplicationsQueryVariables>
    | ReactiveFunction<GetOrganizationApplicationsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetOrganizationApplicationsQuery,
        GetOrganizationApplicationsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationApplicationsQuery,
          GetOrganizationApplicationsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationApplicationsQuery,
          GetOrganizationApplicationsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetOrganizationApplicationsQuery,
    GetOrganizationApplicationsQueryVariables
  >(GetOrganizationApplicationsDocument, variables, options);
}
export function useGetOrganizationApplicationsLazyQuery(
  variables:
    | GetOrganizationApplicationsQueryVariables
    | VueCompositionApi.Ref<GetOrganizationApplicationsQueryVariables>
    | ReactiveFunction<GetOrganizationApplicationsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetOrganizationApplicationsQuery,
        GetOrganizationApplicationsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationApplicationsQuery,
          GetOrganizationApplicationsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationApplicationsQuery,
          GetOrganizationApplicationsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetOrganizationApplicationsQuery,
    GetOrganizationApplicationsQueryVariables
  >(GetOrganizationApplicationsDocument, variables, options);
}
export type GetOrganizationApplicationsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetOrganizationApplicationsQuery,
    GetOrganizationApplicationsQueryVariables
  >;
export const GetOrganizationAsigneeRolesDocument = gql`
  query GetOrganizationAsigneeRoles($id: Guid!) {
    organization(id: $id) {
      assigneeRoles {
        id
        name
        description
        application {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetOrganizationAsigneeRolesQuery__
 *
 * To run a query within a Vue component, call `useGetOrganizationAsigneeRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationAsigneeRolesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOrganizationAsigneeRolesQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetOrganizationAsigneeRolesQuery(
  variables:
    | GetOrganizationAsigneeRolesQueryVariables
    | VueCompositionApi.Ref<GetOrganizationAsigneeRolesQueryVariables>
    | ReactiveFunction<GetOrganizationAsigneeRolesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetOrganizationAsigneeRolesQuery,
        GetOrganizationAsigneeRolesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationAsigneeRolesQuery,
          GetOrganizationAsigneeRolesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationAsigneeRolesQuery,
          GetOrganizationAsigneeRolesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetOrganizationAsigneeRolesQuery,
    GetOrganizationAsigneeRolesQueryVariables
  >(GetOrganizationAsigneeRolesDocument, variables, options);
}
export function useGetOrganizationAsigneeRolesLazyQuery(
  variables:
    | GetOrganizationAsigneeRolesQueryVariables
    | VueCompositionApi.Ref<GetOrganizationAsigneeRolesQueryVariables>
    | ReactiveFunction<GetOrganizationAsigneeRolesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetOrganizationAsigneeRolesQuery,
        GetOrganizationAsigneeRolesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationAsigneeRolesQuery,
          GetOrganizationAsigneeRolesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationAsigneeRolesQuery,
          GetOrganizationAsigneeRolesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetOrganizationAsigneeRolesQuery,
    GetOrganizationAsigneeRolesQueryVariables
  >(GetOrganizationAsigneeRolesDocument, variables, options);
}
export type GetOrganizationAsigneeRolesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetOrganizationAsigneeRolesQuery,
    GetOrganizationAsigneeRolesQueryVariables
  >;
export const OrganizationByCvrDocument = gql`
  query OrganizationByCvr($cvr: String!) {
    organizationByCvr(cvr: $cvr) {
      id
      vat
    }
  }
`;

/**
 * __useOrganizationByCvrQuery__
 *
 * To run a query within a Vue component, call `useOrganizationByCvrQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationByCvrQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationByCvrQuery({
 *   cvr: // value for 'cvr'
 * });
 */
export function useOrganizationByCvrQuery(
  variables:
    | OrganizationByCvrQueryVariables
    | VueCompositionApi.Ref<OrganizationByCvrQueryVariables>
    | ReactiveFunction<OrganizationByCvrQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        OrganizationByCvrQuery,
        OrganizationByCvrQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          OrganizationByCvrQuery,
          OrganizationByCvrQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          OrganizationByCvrQuery,
          OrganizationByCvrQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    OrganizationByCvrQuery,
    OrganizationByCvrQueryVariables
  >(OrganizationByCvrDocument, variables, options);
}
export function useOrganizationByCvrLazyQuery(
  variables:
    | OrganizationByCvrQueryVariables
    | VueCompositionApi.Ref<OrganizationByCvrQueryVariables>
    | ReactiveFunction<OrganizationByCvrQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        OrganizationByCvrQuery,
        OrganizationByCvrQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          OrganizationByCvrQuery,
          OrganizationByCvrQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          OrganizationByCvrQuery,
          OrganizationByCvrQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    OrganizationByCvrQuery,
    OrganizationByCvrQueryVariables
  >(OrganizationByCvrDocument, variables, options);
}
export type OrganizationByCvrQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    OrganizationByCvrQuery,
    OrganizationByCvrQueryVariables
  >;
export const OrganizationByNameDocument = gql`
  query OrganizationByName($name: String!) {
    organizationByName(name: $name) {
      id
      name
    }
  }
`;

/**
 * __useOrganizationByNameQuery__
 *
 * To run a query within a Vue component, call `useOrganizationByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationByNameQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationByNameQuery({
 *   name: // value for 'name'
 * });
 */
export function useOrganizationByNameQuery(
  variables:
    | OrganizationByNameQueryVariables
    | VueCompositionApi.Ref<OrganizationByNameQueryVariables>
    | ReactiveFunction<OrganizationByNameQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        OrganizationByNameQuery,
        OrganizationByNameQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          OrganizationByNameQuery,
          OrganizationByNameQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          OrganizationByNameQuery,
          OrganizationByNameQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    OrganizationByNameQuery,
    OrganizationByNameQueryVariables
  >(OrganizationByNameDocument, variables, options);
}
export function useOrganizationByNameLazyQuery(
  variables:
    | OrganizationByNameQueryVariables
    | VueCompositionApi.Ref<OrganizationByNameQueryVariables>
    | ReactiveFunction<OrganizationByNameQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        OrganizationByNameQuery,
        OrganizationByNameQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          OrganizationByNameQuery,
          OrganizationByNameQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          OrganizationByNameQuery,
          OrganizationByNameQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    OrganizationByNameQuery,
    OrganizationByNameQueryVariables
  >(OrganizationByNameDocument, variables, options);
}
export type OrganizationByNameQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    OrganizationByNameQuery,
    OrganizationByNameQueryVariables
  >;
export const GetOrganizationsDocument = gql`
  query GetOrganizations {
    organizations {
      totalCount
      nodes {
        ...CommonOrganizationParts
      }
    }
  }
  ${CommonOrganizationPartsFragmentDoc}
`;

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a Vue component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOrganizationsQuery();
 */
export function useGetOrganizationsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetOrganizationsQuery,
        GetOrganizationsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationsQuery,
          GetOrganizationsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationsQuery,
          GetOrganizationsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetOrganizationsQuery,
    GetOrganizationsQueryVariables
  >(GetOrganizationsDocument, {}, options);
}
export function useGetOrganizationsLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetOrganizationsQuery,
        GetOrganizationsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationsQuery,
          GetOrganizationsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationsQuery,
          GetOrganizationsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetOrganizationsQuery,
    GetOrganizationsQueryVariables
  >(GetOrganizationsDocument, {}, options);
}
export type GetOrganizationsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetOrganizationsQuery,
    GetOrganizationsQueryVariables
  >;
export const GetSelectableOrganizationsDocument = gql`
  query GetSelectableOrganizations {
    organizations {
      nodes {
        name
        logoUrl
        vat
        id
      }
    }
  }
`;

/**
 * __useGetSelectableOrganizationsQuery__
 *
 * To run a query within a Vue component, call `useGetSelectableOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectableOrganizationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSelectableOrganizationsQuery();
 */
export function useGetSelectableOrganizationsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetSelectableOrganizationsQuery,
        GetSelectableOrganizationsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetSelectableOrganizationsQuery,
          GetSelectableOrganizationsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetSelectableOrganizationsQuery,
          GetSelectableOrganizationsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetSelectableOrganizationsQuery,
    GetSelectableOrganizationsQueryVariables
  >(GetSelectableOrganizationsDocument, {}, options);
}
export function useGetSelectableOrganizationsLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetSelectableOrganizationsQuery,
        GetSelectableOrganizationsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetSelectableOrganizationsQuery,
          GetSelectableOrganizationsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetSelectableOrganizationsQuery,
          GetSelectableOrganizationsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetSelectableOrganizationsQuery,
    GetSelectableOrganizationsQueryVariables
  >(GetSelectableOrganizationsDocument, {}, options);
}
export type GetSelectableOrganizationsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetSelectableOrganizationsQuery,
    GetSelectableOrganizationsQueryVariables
  >;
export const TokenSimulationDocument = gql`
  query TokenSimulation($applicationId: Guid!, $organizationId: Guid!) {
    userSimulationToken(
      applicationId: $applicationId
      organizationId: $organizationId
    ) {
      key
      value
    }
  }
`;

/**
 * __useTokenSimulationQuery__
 *
 * To run a query within a Vue component, call `useTokenSimulationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenSimulationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTokenSimulationQuery({
 *   applicationId: // value for 'applicationId'
 *   organizationId: // value for 'organizationId'
 * });
 */
export function useTokenSimulationQuery(
  variables:
    | TokenSimulationQueryVariables
    | VueCompositionApi.Ref<TokenSimulationQueryVariables>
    | ReactiveFunction<TokenSimulationQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        TokenSimulationQuery,
        TokenSimulationQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          TokenSimulationQuery,
          TokenSimulationQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          TokenSimulationQuery,
          TokenSimulationQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    TokenSimulationQuery,
    TokenSimulationQueryVariables
  >(TokenSimulationDocument, variables, options);
}
export function useTokenSimulationLazyQuery(
  variables:
    | TokenSimulationQueryVariables
    | VueCompositionApi.Ref<TokenSimulationQueryVariables>
    | ReactiveFunction<TokenSimulationQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        TokenSimulationQuery,
        TokenSimulationQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          TokenSimulationQuery,
          TokenSimulationQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          TokenSimulationQuery,
          TokenSimulationQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    TokenSimulationQuery,
    TokenSimulationQueryVariables
  >(TokenSimulationDocument, variables, options);
}
export type TokenSimulationQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    TokenSimulationQuery,
    TokenSimulationQueryVariables
  >;
export const CreateRoleDocument = gql`
  mutation CreateRole($input: CreateRoleInput!) {
    roleMutation {
      createRole(input: $input) {
        ...CommonRoleParts
      }
    }
  }
  ${CommonRolePartsFragmentDoc}
`;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateRoleMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateRoleMutation,
        CreateRoleMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateRoleMutation,
          CreateRoleMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateRoleMutation,
    CreateRoleMutationVariables
  >(CreateRoleDocument, options);
}
export type CreateRoleMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateRoleMutation,
    CreateRoleMutationVariables
  >;
export const UpdateRoleDocument = gql`
  mutation UpdateRole($input: UpdateRoleInput!) {
    roleMutation {
      updateRole(input: $input) {
        ...CommonRoleParts
      }
    }
  }
  ${CommonRolePartsFragmentDoc}
`;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateRoleMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateRoleMutation,
        UpdateRoleMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateRoleMutation,
          UpdateRoleMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >(UpdateRoleDocument, options);
}
export type UpdateRoleMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >;
export const DeleteRoleDocument = gql`
  mutation DeleteRole($id: Guid!) {
    roleMutation {
      deleteRole(roleId: $id)
    }
  }
`;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteRoleMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteRoleMutation,
        DeleteRoleMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteRoleMutation,
          DeleteRoleMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteRoleMutation,
    DeleteRoleMutationVariables
  >(DeleteRoleDocument, options);
}
export type DeleteRoleMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteRoleMutation,
    DeleteRoleMutationVariables
  >;
export const RemoveRoleAssigneeDocument = gql`
  mutation RemoveRoleAssignee($input: AddOrRemoveRoleAssigneeInput!) {
    roleMutation {
      removeRoleAssignee(input: $input) {
        id
      }
    }
  }
`;

/**
 * __useRemoveRoleAssigneeMutation__
 *
 * To run a mutation, you first call `useRemoveRoleAssigneeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRoleAssigneeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveRoleAssigneeMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveRoleAssigneeMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveRoleAssigneeMutation,
        RemoveRoleAssigneeMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveRoleAssigneeMutation,
          RemoveRoleAssigneeMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveRoleAssigneeMutation,
    RemoveRoleAssigneeMutationVariables
  >(RemoveRoleAssigneeDocument, options);
}
export type RemoveRoleAssigneeMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveRoleAssigneeMutation,
    RemoveRoleAssigneeMutationVariables
  >;
export const AddRoleAssigneeDocument = gql`
  mutation AddRoleAssignee($input: AddOrRemoveRoleAssigneeInput!) {
    roleMutation {
      addRoleAssignee(input: $input) {
        id
      }
    }
  }
`;

/**
 * __useAddRoleAssigneeMutation__
 *
 * To run a mutation, you first call `useAddRoleAssigneeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleAssigneeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddRoleAssigneeMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useAddRoleAssigneeMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddRoleAssigneeMutation,
        AddRoleAssigneeMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddRoleAssigneeMutation,
          AddRoleAssigneeMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddRoleAssigneeMutation,
    AddRoleAssigneeMutationVariables
  >(AddRoleAssigneeDocument, options);
}
export type AddRoleAssigneeMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddRoleAssigneeMutation,
    AddRoleAssigneeMutationVariables
  >;
export const GetAssignableUsersDocument = gql`
  query GetAssignableUsers(
    $filters: UserFiltersInput
    $pageParams: PageParamsGraphType
    $sortInfo: UserSortInfo
  ) {
    users(filters: $filters, pageParams: $pageParams, sortInfo: $sortInfo) {
      totalCount
      nodes {
        ...CommonUserParts
      }
    }
  }
  ${CommonUserPartsFragmentDoc}
`;

/**
 * __useGetAssignableUsersQuery__
 *
 * To run a query within a Vue component, call `useGetAssignableUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignableUsersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAssignableUsersQuery({
 *   filters: // value for 'filters'
 *   pageParams: // value for 'pageParams'
 *   sortInfo: // value for 'sortInfo'
 * });
 */
export function useGetAssignableUsersQuery(
  variables:
    | GetAssignableUsersQueryVariables
    | VueCompositionApi.Ref<GetAssignableUsersQueryVariables>
    | ReactiveFunction<GetAssignableUsersQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        GetAssignableUsersQuery,
        GetAssignableUsersQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetAssignableUsersQuery,
          GetAssignableUsersQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetAssignableUsersQuery,
          GetAssignableUsersQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetAssignableUsersQuery,
    GetAssignableUsersQueryVariables
  >(GetAssignableUsersDocument, variables, options);
}
export function useGetAssignableUsersLazyQuery(
  variables:
    | GetAssignableUsersQueryVariables
    | VueCompositionApi.Ref<GetAssignableUsersQueryVariables>
    | ReactiveFunction<GetAssignableUsersQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        GetAssignableUsersQuery,
        GetAssignableUsersQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetAssignableUsersQuery,
          GetAssignableUsersQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetAssignableUsersQuery,
          GetAssignableUsersQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetAssignableUsersQuery,
    GetAssignableUsersQueryVariables
  >(GetAssignableUsersDocument, variables, options);
}
export type GetAssignableUsersQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetAssignableUsersQuery,
    GetAssignableUsersQueryVariables
  >;
export const GetRoleDocument = gql`
  query GetRole($id: Guid!) {
    role(id: $id) {
      id
      name
      attribute
      description
      application {
        id
        name
      }
    }
  }
`;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a Vue component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetRoleQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetRoleQuery(
  variables:
    | GetRoleQueryVariables
    | VueCompositionApi.Ref<GetRoleQueryVariables>
    | ReactiveFunction<GetRoleQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<GetRoleQuery, GetRoleQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<GetRoleQuery, GetRoleQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<GetRoleQuery, GetRoleQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<GetRoleQuery, GetRoleQueryVariables>(
    GetRoleDocument,
    variables,
    options
  );
}
export function useGetRoleLazyQuery(
  variables:
    | GetRoleQueryVariables
    | VueCompositionApi.Ref<GetRoleQueryVariables>
    | ReactiveFunction<GetRoleQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<GetRoleQuery, GetRoleQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<GetRoleQuery, GetRoleQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<GetRoleQuery, GetRoleQueryVariables>
      > = {}
) {
  return VueApolloComposable.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(
    GetRoleDocument,
    variables,
    options
  );
}
export type GetRoleQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<GetRoleQuery, GetRoleQueryVariables>;
export const GetRoleAssigneesDocument = gql`
  query GetRoleAssignees($id: Guid!) {
    role(id: $id) {
      assignees {
        id
        name
        vat
        logoUrl
      }
    }
  }
`;

/**
 * __useGetRoleAssigneesQuery__
 *
 * To run a query within a Vue component, call `useGetRoleAssigneesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleAssigneesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetRoleAssigneesQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetRoleAssigneesQuery(
  variables:
    | GetRoleAssigneesQueryVariables
    | VueCompositionApi.Ref<GetRoleAssigneesQueryVariables>
    | ReactiveFunction<GetRoleAssigneesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetRoleAssigneesQuery,
        GetRoleAssigneesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetRoleAssigneesQuery,
          GetRoleAssigneesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetRoleAssigneesQuery,
          GetRoleAssigneesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetRoleAssigneesQuery,
    GetRoleAssigneesQueryVariables
  >(GetRoleAssigneesDocument, variables, options);
}
export function useGetRoleAssigneesLazyQuery(
  variables:
    | GetRoleAssigneesQueryVariables
    | VueCompositionApi.Ref<GetRoleAssigneesQueryVariables>
    | ReactiveFunction<GetRoleAssigneesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetRoleAssigneesQuery,
        GetRoleAssigneesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetRoleAssigneesQuery,
          GetRoleAssigneesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetRoleAssigneesQuery,
          GetRoleAssigneesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetRoleAssigneesQuery,
    GetRoleAssigneesQueryVariables
  >(GetRoleAssigneesDocument, variables, options);
}
export type GetRoleAssigneesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetRoleAssigneesQuery,
    GetRoleAssigneesQueryVariables
  >;
export const GetRoleAssignedUsersDocument = gql`
  query getRoleAssignedUsers($id: Guid!) {
    role(id: $id) {
      assignedUsers {
        nodes {
          lastUse
          organization {
            id
            name
            vat
          }
          expirationDate
          user {
            id
            fullname
            email
            lastLogin
            organization {
              id
              name
              vat
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetRoleAssignedUsersQuery__
 *
 * To run a query within a Vue component, call `useGetRoleAssignedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleAssignedUsersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetRoleAssignedUsersQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetRoleAssignedUsersQuery(
  variables:
    | GetRoleAssignedUsersQueryVariables
    | VueCompositionApi.Ref<GetRoleAssignedUsersQueryVariables>
    | ReactiveFunction<GetRoleAssignedUsersQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetRoleAssignedUsersQuery,
        GetRoleAssignedUsersQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetRoleAssignedUsersQuery,
          GetRoleAssignedUsersQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetRoleAssignedUsersQuery,
          GetRoleAssignedUsersQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetRoleAssignedUsersQuery,
    GetRoleAssignedUsersQueryVariables
  >(GetRoleAssignedUsersDocument, variables, options);
}
export function useGetRoleAssignedUsersLazyQuery(
  variables:
    | GetRoleAssignedUsersQueryVariables
    | VueCompositionApi.Ref<GetRoleAssignedUsersQueryVariables>
    | ReactiveFunction<GetRoleAssignedUsersQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetRoleAssignedUsersQuery,
        GetRoleAssignedUsersQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetRoleAssignedUsersQuery,
          GetRoleAssignedUsersQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetRoleAssignedUsersQuery,
          GetRoleAssignedUsersQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetRoleAssignedUsersQuery,
    GetRoleAssignedUsersQueryVariables
  >(GetRoleAssignedUsersDocument, variables, options);
}
export type GetRoleAssignedUsersQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetRoleAssignedUsersQuery,
    GetRoleAssignedUsersQueryVariables
  >;
export const GetRolesDocument = gql`
  query GetRoles {
    roles {
      totalCount
      nodes {
        ...CommonRoleParts
      }
    }
  }
  ${CommonRolePartsFragmentDoc}
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a Vue component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetRolesQuery();
 */
export function useGetRolesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<GetRolesQuery, GetRolesQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetRolesQuery,
          GetRolesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetRolesQuery,
          GetRolesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<GetRolesQuery, GetRolesQueryVariables>(
    GetRolesDocument,
    {},
    options
  );
}
export function useGetRolesLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<GetRolesQuery, GetRolesQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetRolesQuery,
          GetRolesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetRolesQuery,
          GetRolesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetRolesQuery,
    GetRolesQueryVariables
  >(GetRolesDocument, {}, options);
}
export type GetRolesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<GetRolesQuery, GetRolesQueryVariables>;
export const RoleByAttributeDocument = gql`
  query RoleByAttribute($attribute: String!) {
    roleByAttribute(attribute: $attribute) {
      id
      attribute
      name
    }
  }
`;

/**
 * __useRoleByAttributeQuery__
 *
 * To run a query within a Vue component, call `useRoleByAttributeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleByAttributeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useRoleByAttributeQuery({
 *   attribute: // value for 'attribute'
 * });
 */
export function useRoleByAttributeQuery(
  variables:
    | RoleByAttributeQueryVariables
    | VueCompositionApi.Ref<RoleByAttributeQueryVariables>
    | ReactiveFunction<RoleByAttributeQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        RoleByAttributeQuery,
        RoleByAttributeQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          RoleByAttributeQuery,
          RoleByAttributeQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          RoleByAttributeQuery,
          RoleByAttributeQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    RoleByAttributeQuery,
    RoleByAttributeQueryVariables
  >(RoleByAttributeDocument, variables, options);
}
export function useRoleByAttributeLazyQuery(
  variables:
    | RoleByAttributeQueryVariables
    | VueCompositionApi.Ref<RoleByAttributeQueryVariables>
    | ReactiveFunction<RoleByAttributeQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        RoleByAttributeQuery,
        RoleByAttributeQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          RoleByAttributeQuery,
          RoleByAttributeQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          RoleByAttributeQuery,
          RoleByAttributeQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    RoleByAttributeQuery,
    RoleByAttributeQueryVariables
  >(RoleByAttributeDocument, variables, options);
}
export type RoleByAttributeQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    RoleByAttributeQuery,
    RoleByAttributeQueryVariables
  >;
export const ChangeEmailDocument = gql`
  mutation ChangeEmail($input: UpdateUserProfileInput!) {
    userMutation {
      updateUserProfile(input: $input)
    }
  }
`;

/**
 * __useChangeEmailMutation__
 *
 * To run a mutation, you first call `useChangeEmailMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useChangeEmailMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useChangeEmailMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        ChangeEmailMutation,
        ChangeEmailMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          ChangeEmailMutation,
          ChangeEmailMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    ChangeEmailMutation,
    ChangeEmailMutationVariables
  >(ChangeEmailDocument, options);
}
export type ChangeEmailMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    ChangeEmailMutation,
    ChangeEmailMutationVariables
  >;
export const ConfirmEmailDocument = gql`
  mutation ConfirmEmail($input: ConfirmEmailVerificationInput!) {
    userMutation {
      confirmEmailVerification(input: $input) {
        status
      }
    }
  }
`;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useConfirmEmailMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useConfirmEmailMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        ConfirmEmailMutation,
        ConfirmEmailMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          ConfirmEmailMutation,
          ConfirmEmailMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables
  >(ConfirmEmailDocument, options);
}
export type ConfirmEmailMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables
  >;
export const ConfirmLinkUserDocument = gql`
  mutation ConfirmLinkUser($input: ConfirmLinkNemLoginIdInput!) {
    userMutation {
      confirmLinkNemLoginId(input: $input) {
        status
      }
    }
  }
`;

/**
 * __useConfirmLinkUserMutation__
 *
 * To run a mutation, you first call `useConfirmLinkUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useConfirmLinkUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useConfirmLinkUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useConfirmLinkUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        ConfirmLinkUserMutation,
        ConfirmLinkUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          ConfirmLinkUserMutation,
          ConfirmLinkUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    ConfirmLinkUserMutation,
    ConfirmLinkUserMutationVariables
  >(ConfirmLinkUserDocument, options);
}
export type ConfirmLinkUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    ConfirmLinkUserMutation,
    ConfirmLinkUserMutationVariables
  >;
export const ResendEmailDocument = gql`
  mutation ResendEmail($input: Guid!) {
    userMutation {
      resendEmailVerification(userId: $input)
    }
  }
`;

/**
 * __useResendEmailMutation__
 *
 * To run a mutation, you first call `useResendEmailMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useResendEmailMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useResendEmailMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        ResendEmailMutation,
        ResendEmailMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          ResendEmailMutation,
          ResendEmailMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    ResendEmailMutation,
    ResendEmailMutationVariables
  >(ResendEmailDocument, options);
}
export type ResendEmailMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    ResendEmailMutation,
    ResendEmailMutationVariables
  >;
export const CreateTestUserDocument = gql`
  mutation CreateTestUser($input: CreateTestUserInput!) {
    userMutation {
      createTestUser(input: $input) {
        ...CommonUserParts
      }
    }
  }
  ${CommonUserPartsFragmentDoc}
`;

/**
 * __useCreateTestUserMutation__
 *
 * To run a mutation, you first call `useCreateTestUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateTestUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateTestUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateTestUserMutation,
        CreateTestUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateTestUserMutation,
          CreateTestUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateTestUserMutation,
    CreateTestUserMutationVariables
  >(CreateTestUserDocument, options);
}
export type CreateTestUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateTestUserMutation,
    CreateTestUserMutationVariables
  >;
export const RemoveApplicationAdminAssignmentDocument = gql`
  mutation RemoveApplicationAdminAssignment(
    $input: AddOrRemoveUserApplicationAssignmentInput!
  ) {
    userMutation {
      removeApplication(input: $input) {
        id
      }
    }
  }
`;

/**
 * __useRemoveApplicationAdminAssignmentMutation__
 *
 * To run a mutation, you first call `useRemoveApplicationAdminAssignmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApplicationAdminAssignmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveApplicationAdminAssignmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveApplicationAdminAssignmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveApplicationAdminAssignmentMutation,
        RemoveApplicationAdminAssignmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveApplicationAdminAssignmentMutation,
          RemoveApplicationAdminAssignmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveApplicationAdminAssignmentMutation,
    RemoveApplicationAdminAssignmentMutationVariables
  >(RemoveApplicationAdminAssignmentDocument, options);
}
export type RemoveApplicationAdminAssignmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveApplicationAdminAssignmentMutation,
    RemoveApplicationAdminAssignmentMutationVariables
  >;
export const AssignApplicationAdminAssignmentDocument = gql`
  mutation AssignApplicationAdminAssignment(
    $input: AddOrRemoveUserApplicationAssignmentInput!
  ) {
    userMutation {
      assignApplication(input: $input) {
        id
      }
    }
  }
`;

/**
 * __useAssignApplicationAdminAssignmentMutation__
 *
 * To run a mutation, you first call `useAssignApplicationAdminAssignmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAssignApplicationAdminAssignmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAssignApplicationAdminAssignmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useAssignApplicationAdminAssignmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AssignApplicationAdminAssignmentMutation,
        AssignApplicationAdminAssignmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AssignApplicationAdminAssignmentMutation,
          AssignApplicationAdminAssignmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AssignApplicationAdminAssignmentMutation,
    AssignApplicationAdminAssignmentMutationVariables
  >(AssignApplicationAdminAssignmentDocument, options);
}
export type AssignApplicationAdminAssignmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AssignApplicationAdminAssignmentMutation,
    AssignApplicationAdminAssignmentMutationVariables
  >;
export const AssignRoleToUserDocument = gql`
  mutation AssignRoleToUser($input: AssignUserRoleInput!) {
    userMutation {
      assignRole(input: $input) {
        id
      }
    }
  }
`;

/**
 * __useAssignRoleToUserMutation__
 *
 * To run a mutation, you first call `useAssignRoleToUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAssignRoleToUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAssignRoleToUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useAssignRoleToUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AssignRoleToUserMutation,
        AssignRoleToUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AssignRoleToUserMutation,
          AssignRoleToUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AssignRoleToUserMutation,
    AssignRoleToUserMutationVariables
  >(AssignRoleToUserDocument, options);
}
export type AssignRoleToUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AssignRoleToUserMutation,
    AssignRoleToUserMutationVariables
  >;
export const RemoveRoleFromUserDocument = gql`
  mutation RemoveRoleFromUser($input: RemoveUserRoleInput!) {
    userMutation {
      removeRole(input: $input) {
        id
        roleAssignments {
          role {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useRemoveRoleFromUserMutation__
 *
 * To run a mutation, you first call `useRemoveRoleFromUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRoleFromUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveRoleFromUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveRoleFromUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveRoleFromUserMutation,
        RemoveRoleFromUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveRoleFromUserMutation,
          RemoveRoleFromUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveRoleFromUserMutation,
    RemoveRoleFromUserMutationVariables
  >(RemoveRoleFromUserDocument, options);
}
export type RemoveRoleFromUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveRoleFromUserMutation,
    RemoveRoleFromUserMutationVariables
  >;
export const UpdateRoleAssignmentDocument = gql`
  mutation UpdateRoleAssignment(
    $input: UpdateUserRoleAssignmentExpiryDateInput!
  ) {
    userMutation {
      updateUserRoleAssignmentExpiryDate(input: $input) {
        user {
          id
        }
        role {
          id
        }
      }
    }
  }
`;

/**
 * __useUpdateRoleAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateRoleAssignmentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleAssignmentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateRoleAssignmentMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleAssignmentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateRoleAssignmentMutation,
        UpdateRoleAssignmentMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateRoleAssignmentMutation,
          UpdateRoleAssignmentMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateRoleAssignmentMutation,
    UpdateRoleAssignmentMutationVariables
  >(UpdateRoleAssignmentDocument, options);
}
export type UpdateRoleAssignmentMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateRoleAssignmentMutation,
    UpdateRoleAssignmentMutationVariables
  >;
export const AssignRoleOnBehalfOfDocument = gql`
  mutation AssignRoleOnBehalfOf($input: AssignUserRoleOnBehalfOfInput!) {
    userMutation {
      assignRoleOnBehalfOf(input: $input) {
        ...CommonUserParts
      }
    }
  }
  ${CommonUserPartsFragmentDoc}
`;

/**
 * __useAssignRoleOnBehalfOfMutation__
 *
 * To run a mutation, you first call `useAssignRoleOnBehalfOfMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAssignRoleOnBehalfOfMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAssignRoleOnBehalfOfMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useAssignRoleOnBehalfOfMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AssignRoleOnBehalfOfMutation,
        AssignRoleOnBehalfOfMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AssignRoleOnBehalfOfMutation,
          AssignRoleOnBehalfOfMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AssignRoleOnBehalfOfMutation,
    AssignRoleOnBehalfOfMutationVariables
  >(AssignRoleOnBehalfOfDocument, options);
}
export type AssignRoleOnBehalfOfMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AssignRoleOnBehalfOfMutation,
    AssignRoleOnBehalfOfMutationVariables
  >;
export const DeleteUserDocument = gql`
  mutation DeleteUser($id: Guid!) {
    userMutation {
      deleteUser(userId: $id)
    }
  }
`;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteUserMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteUserMutation,
        DeleteUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteUserMutation,
          DeleteUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >(DeleteUserDocument, options);
}
export type DeleteUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    userMutation {
      updateUser(input: $input) {
        ...CommonUserParts
      }
    }
  }
  ${CommonUserPartsFragmentDoc}
`;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateUserMutation,
        UpdateUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateUserMutation,
          UpdateUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UpdateUserDocument, options);
}
export type UpdateUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >;
export const GetUserDocument = gql`
  query GetUser($id: Guid!) {
    user(id: $id) {
      id
      firstname
      lastname
      fullname
      email
      description
      organization {
        id
        vat
        name
        logoUrl
      }
      roleAssignments {
        role {
          id
          attribute
        }
      }
      nemloginId
      emailVerificationDate
      upstreamIdpIds {
        upstreamIdpId
        upstreamIdpUserId
      }
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a Vue component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUserQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetUserQuery(
  variables:
    | GetUserQueryVariables
    | VueCompositionApi.Ref<GetUserQueryVariables>
    | ReactiveFunction<GetUserQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<GetUserQuery, GetUserQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<GetUserQuery, GetUserQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<GetUserQuery, GetUserQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    variables,
    options
  );
}
export function useGetUserLazyQuery(
  variables:
    | GetUserQueryVariables
    | VueCompositionApi.Ref<GetUserQueryVariables>
    | ReactiveFunction<GetUserQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<GetUserQuery, GetUserQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<GetUserQuery, GetUserQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<GetUserQuery, GetUserQueryVariables>
      > = {}
) {
  return VueApolloComposable.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    variables,
    options
  );
}
export type GetUserQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<GetUserQuery, GetUserQueryVariables>;
export const GetUserApplicationsDocument = gql`
  query GetUserApplications($id: Guid!) {
    user(id: $id) {
      applicationAssignments {
        id
        name
        roles {
          totalCount
        }
        ownerOrganization {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetUserApplicationsQuery__
 *
 * To run a query within a Vue component, call `useGetUserApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserApplicationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUserApplicationsQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetUserApplicationsQuery(
  variables:
    | GetUserApplicationsQueryVariables
    | VueCompositionApi.Ref<GetUserApplicationsQueryVariables>
    | ReactiveFunction<GetUserApplicationsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetUserApplicationsQuery,
        GetUserApplicationsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetUserApplicationsQuery,
          GetUserApplicationsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetUserApplicationsQuery,
          GetUserApplicationsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetUserApplicationsQuery,
    GetUserApplicationsQueryVariables
  >(GetUserApplicationsDocument, variables, options);
}
export function useGetUserApplicationsLazyQuery(
  variables:
    | GetUserApplicationsQueryVariables
    | VueCompositionApi.Ref<GetUserApplicationsQueryVariables>
    | ReactiveFunction<GetUserApplicationsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetUserApplicationsQuery,
        GetUserApplicationsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetUserApplicationsQuery,
          GetUserApplicationsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetUserApplicationsQuery,
          GetUserApplicationsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetUserApplicationsQuery,
    GetUserApplicationsQueryVariables
  >(GetUserApplicationsDocument, variables, options);
}
export type GetUserApplicationsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetUserApplicationsQuery,
    GetUserApplicationsQueryVariables
  >;
export const GetUserByEmailDocument = gql`
  query GetUserByEmail($email: String!) {
    userByEmail(email: $email) {
      id
      organization {
        id
        name
        logoUrl
        vat
      }
    }
  }
`;

/**
 * __useGetUserByEmailQuery__
 *
 * To run a query within a Vue component, call `useGetUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByEmailQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUserByEmailQuery({
 *   email: // value for 'email'
 * });
 */
export function useGetUserByEmailQuery(
  variables:
    | GetUserByEmailQueryVariables
    | VueCompositionApi.Ref<GetUserByEmailQueryVariables>
    | ReactiveFunction<GetUserByEmailQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetUserByEmailQuery,
        GetUserByEmailQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetUserByEmailQuery,
          GetUserByEmailQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetUserByEmailQuery,
          GetUserByEmailQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetUserByEmailQuery,
    GetUserByEmailQueryVariables
  >(GetUserByEmailDocument, variables, options);
}
export function useGetUserByEmailLazyQuery(
  variables:
    | GetUserByEmailQueryVariables
    | VueCompositionApi.Ref<GetUserByEmailQueryVariables>
    | ReactiveFunction<GetUserByEmailQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetUserByEmailQuery,
        GetUserByEmailQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetUserByEmailQuery,
          GetUserByEmailQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetUserByEmailQuery,
          GetUserByEmailQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetUserByEmailQuery,
    GetUserByEmailQueryVariables
  >(GetUserByEmailDocument, variables, options);
}
export type GetUserByEmailQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetUserByEmailQuery,
    GetUserByEmailQueryVariables
  >;
export const GetUserRoleAssignmentsDocument = gql`
  query GetUserRoleAssignments($id: Guid!) {
    user(id: $id) {
      ...CommonUserRoleAssignmentParts
    }
  }
  ${CommonUserRoleAssignmentPartsFragmentDoc}
`;

/**
 * __useGetUserRoleAssignmentsQuery__
 *
 * To run a query within a Vue component, call `useGetUserRoleAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleAssignmentsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUserRoleAssignmentsQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetUserRoleAssignmentsQuery(
  variables:
    | GetUserRoleAssignmentsQueryVariables
    | VueCompositionApi.Ref<GetUserRoleAssignmentsQueryVariables>
    | ReactiveFunction<GetUserRoleAssignmentsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetUserRoleAssignmentsQuery,
        GetUserRoleAssignmentsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetUserRoleAssignmentsQuery,
          GetUserRoleAssignmentsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetUserRoleAssignmentsQuery,
          GetUserRoleAssignmentsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetUserRoleAssignmentsQuery,
    GetUserRoleAssignmentsQueryVariables
  >(GetUserRoleAssignmentsDocument, variables, options);
}
export function useGetUserRoleAssignmentsLazyQuery(
  variables:
    | GetUserRoleAssignmentsQueryVariables
    | VueCompositionApi.Ref<GetUserRoleAssignmentsQueryVariables>
    | ReactiveFunction<GetUserRoleAssignmentsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetUserRoleAssignmentsQuery,
        GetUserRoleAssignmentsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetUserRoleAssignmentsQuery,
          GetUserRoleAssignmentsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetUserRoleAssignmentsQuery,
          GetUserRoleAssignmentsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetUserRoleAssignmentsQuery,
    GetUserRoleAssignmentsQueryVariables
  >(GetUserRoleAssignmentsDocument, variables, options);
}
export type GetUserRoleAssignmentsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetUserRoleAssignmentsQuery,
    GetUserRoleAssignmentsQueryVariables
  >;
export const GetUserRoleAssignmentsByEmailDocument = gql`
  query GetUserRoleAssignmentsByEmail($email: String!) {
    user: userByEmail(email: $email) {
      ...CommonUserRoleAssignmentParts
    }
  }
  ${CommonUserRoleAssignmentPartsFragmentDoc}
`;

/**
 * __useGetUserRoleAssignmentsByEmailQuery__
 *
 * To run a query within a Vue component, call `useGetUserRoleAssignmentsByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleAssignmentsByEmailQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUserRoleAssignmentsByEmailQuery({
 *   email: // value for 'email'
 * });
 */
export function useGetUserRoleAssignmentsByEmailQuery(
  variables:
    | GetUserRoleAssignmentsByEmailQueryVariables
    | VueCompositionApi.Ref<GetUserRoleAssignmentsByEmailQueryVariables>
    | ReactiveFunction<GetUserRoleAssignmentsByEmailQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetUserRoleAssignmentsByEmailQuery,
        GetUserRoleAssignmentsByEmailQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetUserRoleAssignmentsByEmailQuery,
          GetUserRoleAssignmentsByEmailQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetUserRoleAssignmentsByEmailQuery,
          GetUserRoleAssignmentsByEmailQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetUserRoleAssignmentsByEmailQuery,
    GetUserRoleAssignmentsByEmailQueryVariables
  >(GetUserRoleAssignmentsByEmailDocument, variables, options);
}
export function useGetUserRoleAssignmentsByEmailLazyQuery(
  variables:
    | GetUserRoleAssignmentsByEmailQueryVariables
    | VueCompositionApi.Ref<GetUserRoleAssignmentsByEmailQueryVariables>
    | ReactiveFunction<GetUserRoleAssignmentsByEmailQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetUserRoleAssignmentsByEmailQuery,
        GetUserRoleAssignmentsByEmailQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetUserRoleAssignmentsByEmailQuery,
          GetUserRoleAssignmentsByEmailQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetUserRoleAssignmentsByEmailQuery,
          GetUserRoleAssignmentsByEmailQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetUserRoleAssignmentsByEmailQuery,
    GetUserRoleAssignmentsByEmailQueryVariables
  >(GetUserRoleAssignmentsByEmailDocument, variables, options);
}
export type GetUserRoleAssignmentsByEmailQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetUserRoleAssignmentsByEmailQuery,
    GetUserRoleAssignmentsByEmailQueryVariables
  >;
export const GetUsersDocument = gql`
  query GetUsers(
    $filters: UserFiltersInput
    $pageParams: PageParamsGraphType
    $sortInfo: UserSortInfo
  ) {
    users(filters: $filters, pageParams: $pageParams, sortInfo: $sortInfo) {
      totalCount
      nodes {
        nemloginId
        ...CommonUserParts
      }
    }
  }
  ${CommonUserPartsFragmentDoc}
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a Vue component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUsersQuery({
 *   filters: // value for 'filters'
 *   pageParams: // value for 'pageParams'
 *   sortInfo: // value for 'sortInfo'
 * });
 */
export function useGetUsersQuery(
  variables:
    | GetUsersQueryVariables
    | VueCompositionApi.Ref<GetUsersQueryVariables>
    | ReactiveFunction<GetUsersQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<GetUsersQuery, GetUsersQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetUsersQuery,
          GetUsersQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetUsersQuery,
          GetUsersQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    variables,
    options
  );
}
export function useGetUsersLazyQuery(
  variables:
    | GetUsersQueryVariables
    | VueCompositionApi.Ref<GetUsersQueryVariables>
    | ReactiveFunction<GetUsersQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<GetUsersQuery, GetUsersQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetUsersQuery,
          GetUsersQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetUsersQuery,
          GetUsersQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useLazyQuery<
    GetUsersQuery,
    GetUsersQueryVariables
  >(GetUsersDocument, variables, options);
}
export type GetUsersQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<GetUsersQuery, GetUsersQueryVariables>;
