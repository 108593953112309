// import { onError } from '@apollo/client/link/error'
import useOidc from "@/composables/useOidc";
import { DynamicConstants } from "@/constants";
import { createUploadLink } from "apollo-upload-client";
import Vue from "vue";
import ErrorToast from "@/components/ErrorToast.vue";
import BackendError from "@/interfaces/BackendError";
import { ToastContent } from "vue-toastification/dist/types/src/types";
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import VueRouter from "vue-router";

export let client: ApolloClient<NormalizedCacheObject> | null = null;

export default function useCreateGraphqlClient(
  constants: DynamicConstants,
  router: VueRouter
) {
  const httpLink = createUploadLink({
    // You should use an absolute URL here
    uri: constants.apiUrl,
  }) as unknown as ApolloLink;

  const errorLink = onError((error) => {
    if (error.graphQLErrors == null) return;
    if (error.graphQLErrors.length == 0) return;
    const data = error.graphQLErrors[0].extensions["data"].errorDetail;
    if (data == null) return;
    const backendError: BackendError = {
      ...data,
    };

    if (backendError.errorCode == 7) {
      router.replace({
        name: "404",
        params: { 0: router.currentRoute.fullPath },
      });
    }

    const content: ToastContent = {
      component: ErrorToast,
      props: {
        error: backendError,
      },
    };

    Vue.$toast(content, {
      toastClassName: "alert alert-danger dismissable px-4 w-100",
    });
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = useOidc().user.value?.access_token;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  authLink.concat(errorLink);

  // Cache implementation
  const cache = new InMemoryCache();

  // Create the apollo client
  const apolloClient = new ApolloClient({
    link: authLink.concat(errorLink).concat(httpLink),
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
      },
    },
  });

  // Due to bug this does not work atm.
  apolloClient.defaultOptions = {
    query: {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: false,
    },
  };

  return (client = apolloClient);
}
