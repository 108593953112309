import { BvToast } from "bootstrap-vue/src/components/toast";
import { CreateElement } from "vue";
import Cookies from "js-cookie";
const notificationCookieId = (id: number) => `dmp-notification-${id}`;

interface APINotification {
  NotificationId: number;
  Title: string;
  Description: string;
  ValidFrom: string;
  ValidTo: string;

  Type: "info" | "error" | "warning";
}

const checkForDmpNotifications = async (
  uri: string,
  toast: BvToast,
  createElement: CreateElement
): Promise<void> => {
  const notifications = await fetchNotifications(uri);

  notifications.forEach((o) => {
    if (!isShown(o.NotificationId) && isValid(o.ValidFrom, o.ValidTo)) {
      // Custom close button that handling the click event ourself
      const closeButtonHtml = `<button type="button" aria-label="Close" class="close position-absolute" style="top: 2px; right: 6px;" onclick="hideToast('${o.NotificationId}')">×</button>`;
      const innerHTML = `${o.Description}${closeButtonHtml}`;
      const description = createElement("div", {
        domProps: { innerHTML: innerHTML },
      });

      toast.toast(description, {
        id: "" + o.NotificationId,
        title: o.Title,
        variant: mapType(o.Type),
        toastClass: `toast toast-${mapType(o.Type)}`,
        autoHideDelay: 5 * 60 * 1000, // ms
        noCloseButton: true,
      });
    }
  });
};

const mapType = (type: string) => {
  switch (type.toLowerCase()) {
    case "info":
      return "info";
    case "warning":
      return "warning";
    case "error":
      return "danger";
  }
};

const isValid = (start?: string, end?: string) => {
  const startDate = start ? new Date(start) : undefined;
  const endDate = end ? new Date(end) : undefined;

  if (!!startDate && !!endDate) {
    const now = new Date();
    return now >= startDate && now <= endDate;
  } else {
    return true;
  }
};

const fetchNotifications = async (uri: string): Promise<APINotification[]> => {
  return await fetch(uri, { method: "GET" })
    .then((o) => o.json())
    .catch(() => []);
};

const isShown = (notificationId: number): boolean => {
  return Cookies.get(notificationCookieId(notificationId)) !== undefined;
};

const markAsShown = (notificationId: number) => {
  Cookies.set(notificationCookieId(notificationId), "true", {
    expires: 30,
    path: "",
  });
};

export { checkForDmpNotifications, markAsShown };
