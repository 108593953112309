import getBrowserLocale from "./getBrowserLocale";
import supportedLocales from "../supported-locales";
import { LSK_CURRENT_LANGUAGE } from "@/constants";

export default function getStartingLocale() {
  const browserLocale = getBrowserLocale();
  const startingLang =
    localStorage.getItem(LSK_CURRENT_LANGUAGE) || browserLocale;
  if (supportedLocales.find((value) => value.code == startingLang)) {
    return startingLang;
  } else {
    return process.env.VUE_APP_I18N_LOCALE || "en";
  }
}
