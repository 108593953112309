import { readonly, ref } from "@vue/composition-api";

const isLoading = ref(false);

export default function () {
  function startLoading() {
    isLoading.value = true;
  }

  function stopLoading() {
    if (isLoading.value) isLoading.value = false;
  }

  return {
    startLoading,
    stopLoading,
    isLoading: readonly(isLoading),
  };
}
