import Vue from "vue";
import "./jquery";

import VueCompositionAPI, { provide } from "@vue/composition-api";
Vue.use(VueCompositionAPI);

import App from "./App.vue";
import router from "@/router/index";
import { BootstrapVue } from "bootstrap-vue";

import Constants, { DynamicConstants } from "./constants";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

import PortalVue from "@linusborg/vue-simple-portal";

Vue.use(PortalVue);

Vue.component("font-awesome-icon", FontAwesomeIcon);
import vSelect from "vue-select";

Vue.component("v-select", vSelect);

import oidc from "@/composables/useOidc";

import { provideToast } from "vue-toastification/composition";
// Also import the toast's css
import "vue-toastification/dist/index.css";

import "@/styles/icons.ts";
import "@/styles/style.scss";
import axios from "axios";
import { i18n } from "./i18n";

i18n.silentFallbackWarn = true;
i18n.silentTranslationWarn = true;

import apolloClient from "./graphql/client";
import Toast from "vue-toastification";
import { DefaultApolloClient } from "@vue/apollo-composable";
import "./filters/formatDateFilter";
import { PluginOptions } from "vue-toastification/dist/types/src/types";

const toastOptions: PluginOptions = {
  timeout: false,
  closeButton: false,
  closeOnClick: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
  icon: false,
  maxToasts: 1,
  transition: {
    enter: "Vue-Toastification__bounce-enter-active",
    leave: "fade",
    move: "fade",
  },
};

// Enables toast globally
Vue.use(Toast, toastOptions);

axios
  .get(Constants.config_url)
  .then(({ data }) => startApp(new DynamicConstants(data)));

async function startApp(constants: DynamicConstants) {
  oidc()
    .initialize(constants.authority, constants.clientId, router)
    .then(() => {
      const vue = new Vue({
        router,
        i18n,
        setup() {
          // Enables toast in component setup, using provide/inject
          provideToast(toastOptions);
          provide(DefaultApolloClient, apolloClient(constants, router));
        },

        render: (h) => h(App),
      });

      vue.$mount("#app");
    });
}

declare global {
  // Extend the window interface to support hide toast,
  // the concreate implemnet of hideToast will be registered at App component
  interface Window {
    hideToast: (id: string) => void;
  }
}
