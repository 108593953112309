// CONFIG

export abstract class Constants {
  public static readonly config_url = "/config.json";
  public static readonly oidc_callback_uri = "/oidc_callback";
  public static readonly oidc_logout_uri = "/loggedout";
  public static BS_CENTRAL_ADMIN = "brugerstyring_central_admin";
  public static BS_TECHNICAL_ADMIN = "brugerstyring_technical_admin";
  public static BS_LOCAL_ADMIN = "brugerstyring_local_admin";
  public static BS_SUPPORT_ADMIN = "brugerstyring_support_admin";
  public static BS_APP_ADMIN = "brugerstyring_app_admin";
  public static BS_READER = "brugerstyring_reader";
  public static BS_APPLICATION_ID = "6103bba4-90e3-4a4d-ae8e-fdea96239967";
  public static dmpNotificationUri = "";
  public static isShowTestUserTab = false;
}

interface IPublicConfig {
  apiUrl: string;
  authority: string;
  clientId: string;
  dmpNotificationUri: string;
  isShowTestUserTab: boolean;
}

export class DynamicConstants extends Constants {
  public config_url = Constants.config_url;
  public oidc_callback_uri = Constants.oidc_callback_uri;
  public oidc_logout_uri = Constants.oidc_logout_uri;

  public apiUrl: string;
  public authority: string;
  public clientId: string;
  public dmpNotificationUri: string;
  public isShowTestUserTab: boolean;

  constructor(data: IPublicConfig) {
    super();
    this.apiUrl = data.apiUrl;
    this.authority = data.authority;
    this.clientId = data.clientId;
    this.dmpNotificationUri = data.dmpNotificationUri;
    this.isShowTestUserTab = data.isShowTestUserTab;
    Constants.dmpNotificationUri = data.dmpNotificationUri;
    Constants.isShowTestUserTab = data.isShowTestUserTab;
  }
}

export const CONFIG_URI = "/config";
export const OIDC_LOGOUT_URI = "/loggedout";

// OIDC
export const OIDC_CALLBACK_URI = "/callback";

export const OIDC_SILENT_RENEW_URI = "/oidc-silent-renew";
export const OIDC_SCOPE = "openid";
export const OIDC_RESPONSE_TYPE = "code";
export const OIDC_EXPIRING_NOTIF_TIME = 120;

// LOCALSTORAGE KEYS (LSK)
export const LSK_CURRENT_LANGUAGE = "currentLanguage";

// CLAIM
export const BS_CENTRAL_ADMIN = "brugerstyring_central_admin";
export const BS_LOCAL_ADMIN = "brugerstyring_local_admin";
export const BS_SUPPORT_ADMIN = "brugerstyring_support_admin";
export const BS_TECHNICAL_ADMIN = "brugerstyring_technical_admin";
export const BS_APP_ADMIN = "brugerstyring_app_admin";
export const BS_READER = "brugerstyring_reader";

export default Constants;
