import Constants, {
  BS_APP_ADMIN,
  BS_CENTRAL_ADMIN,
  BS_LOCAL_ADMIN,
  BS_SUPPORT_ADMIN,
  BS_TECHNICAL_ADMIN,
} from "@/constants";
import { Organization, User, UserProfile } from "@/graphql/types";
import VueCompositionAPI, {
  computed,
  readonly,
  ref,
} from "@vue/composition-api";
import Vue from "vue";
Vue.use(VueCompositionAPI);

const profile = ref<User>();
const oboOrganization = ref<Organization>();

export default function () {
  function setProfile(value: UserProfile | undefined) {
    profile.value = value?.user;
    oboOrganization.value = value?.oboOrganization;
  }

  function setEmail(email: string) {
    if (email && profile.value) {
      profile.value = {
        ...profile.value,
        email: email,
      };
    }
  }

  function isHomeOrg(value: Organization | string | undefined) {
    if (isString(value)) {
      return (
        oboOrganization.value?.vat == value ||
        oboOrganization.value?.id == value
      );
    }

    if (isOrganization(value)) {
      return (
        oboOrganization.value?.vat == value.vat ||
        oboOrganization.value?.id == value.id
      );
    }
    return false;
  }
  const brugerstyringRoles = computed(() =>
    profile.value?.roleAssignments
      ?.filter(
        (element) => element?.organization?.id === oboOrganization.value?.id
      )
      .flatMap((element) => element?.role ?? [])
      .filter((role) => role.application?.id === Constants.BS_APPLICATION_ID)
  );
  const isCentralAdmin = computed(
    () =>
      brugerstyringRoles.value?.some(
        (element) => element?.attribute == BS_CENTRAL_ADMIN
      ) ?? false
  );

  const isLocalAdmin = computed(
    () =>
      brugerstyringRoles.value?.some(
        (element) => element?.attribute == BS_LOCAL_ADMIN
      ) ?? false
  );

  const isAppAdmin = computed(
    () =>
      brugerstyringRoles.value?.some(
        (element) => element?.attribute == BS_APP_ADMIN
      ) ?? false
  );

  const isSupportAdmin = computed(
    () =>
      brugerstyringRoles.value?.some(
        (element) => element?.attribute == BS_SUPPORT_ADMIN
      ) ?? false
  );

  const isTechnicalAdmin = computed(
    () =>
      brugerstyringRoles.value?.some(
        (element) => element?.attribute == BS_TECHNICAL_ADMIN
      ) ?? false
  );

  const homeOrgAssigneeRoles = computed(
    () => oboOrganization.value?.assigneeRoles ?? []
  );

  const effectRole = computed(() => {
    if (isCentralAdmin.value) return Constants.BS_CENTRAL_ADMIN;
    if (isSupportAdmin.value) return Constants.BS_SUPPORT_ADMIN;
    if (isLocalAdmin.value) return Constants.BS_LOCAL_ADMIN;
    if (isAppAdmin.value) return Constants.BS_APP_ADMIN;
    if (isTechnicalAdmin.value) return Constants.BS_TECHNICAL_ADMIN;
    return "";
  });

  const homeOrgApplications = computed(() => {
    const orgApplications = oboOrganization.value?.applications ?? [];
    const asigneeApplications = homeOrgAssigneeRoles.value.flatMap(
      (element) => element?.application ?? []
    );
    return (
      orgApplications
        .concat(asigneeApplications)
        .filter(
          (application, index, self) =>
            self
              .map((application) => application?.id)
              .indexOf(application?.id) === index
        ) || []
    );
  });

  return {
    profile: readonly(profile),
    setProfile,
    isHomeOrg,
    setEmail,
    homeOrg: computed(() => oboOrganization.value ?? null),
    homeOrgAssigneeRoles,
    homeOrgApplications,
    isCentralAdmin,
    isLocalAdmin,
    isAppAdmin,
    isSupportAdmin,
    isTechnicalAdmin,
    effectRole,
    roles: computed(() =>
      profile.value?.roleAssignments?.flatMap((element) => element?.role ?? [])
    ),
    brugerstyringRoles,
    userHome: computed(() => {
      if (isCentralAdmin.value) {
        return "/organizations";
      } else if (isLocalAdmin.value) {
        return "/users";
      } else if (isAppAdmin.value) {
        return "/applications";
      } else {
        return "/profile";
      }
    }),
    canChangeProfileEmail: computed(
      () => profile.value?.nemloginId && !profile.value?.isLinkedNemLogIn
    ),
    applicationAssignments: computed(
      () => profile.value?.applicationAssignments || []
    ),
    canResendVerifyEmail: computed(
      () => profile.value?.emailVerificationDate == null
    ),
  };
}

function isString(value: Organization | string | undefined): value is string {
  return typeof value === "string";
}

function isOrganization(
  value: Organization | string | undefined
): value is Organization {
  return (value as Organization)?.__typename == "Organization";
}
