<template>
  <div class="py-3">
    <div class="d-flex align-items-center">
      <font-awesome-icon icon="exclamation-triangle" size="lg" class="mr-4" />
      <div class="d-flex flex-column">
        <h5 class="mb-0">{{ $tc(errorTitle) }}</h5>
        <small>
          <a class="ml-auto text-muted" @click="expanded = !expanded">{{
            $tc("expand")
          }}</a></small
        >
      </div>
      <b-button
        class="text-muted ml-auto bg-transparent border-0"
        @click="$emit('close-toast')"
      >
        <font-awesome-icon icon="times" />
      </b-button>
    </div>
    <b-collapse v-if="error" v-model="expanded">
      <hr class="my-4" />
      <b-row v-if="error.errorCode">
        <b-col cols="3">
          <p class="mb-0">{{ $tc("errorCode") }}</p>
        </b-col>
        <b-col>
          <b>{{ error.errorCode }}</b>
        </b-col>
      </b-row>
      <b-row v-if="error.datetime">
        <b-col cols="3">
          <p class="mb-0">{{ $tc("date") }}</p>
        </b-col>
        <b-col>
          <b>{{ error.datetime | formatDateTime }}</b>
        </b-col>
      </b-row>
      <b-row v-if="error.correlationId">
        <b-col cols="3">
          <p class="mb-0">{{ $tc("reference") }}</p>
        </b-col>
        <b-col>
          <b>{{ error.correlationId }}</b>
        </b-col>
      </b-row>
      <b-row v-if="error.message" class="mt-3">
        <b-col cols="3">
          <p class="mb-0">{{ $tc("message") }}</p>
        </b-col>
        <b-col>
          <b>{{ error.message }}</b>
        </b-col>
      </b-row>
      <b-row v-if="error.additionalInfo" class="mt-3">
        <b-col cols="3">
          <p class="mb-0">{{ $tc("additionalInformation") }}</p>
        </b-col>
        <b-col>
          <b>{{ error.additionalInfo }}</b>
        </b-col>
      </b-row>
    </b-collapse>
  </div>
</template>

<i18n>
{
  "en": {
    "expand": "Press here to see more details",
    "errorCode": "Error code:",
    "date": "Date:",
    "reference": "Reference ID:",
    "message": "Error message:",
    "additionalInformation": "Additional information:",
    "errors": {
      "1": "Missing required information",
      "2": "Entered information is not unique",
      "3": "Entered information is invalid format",
      "4": "Unsupported format",
      "5": "Unsupported dimensions",
      "6": "File is too big",
      "7": "Does not exist",
      "100": "You do not have sufficient rights",
      "101": "This user can't be deleted",
      "102": "This role can't be removed",
      "103": "This application can't be deleted",
      "104": "This organization can't be deleted",
      "105": "This role can't be deleted",
      "403": "You are not authorized for this operation",
      "default": "An unknown error occurred"
    }
  },
   "da": {
    "expand": "Klik her for at se flere detajler",
    "errorCode": "Fejlkode:",
    "date": "Dato:",
    "reference": "Reference ID:",
    "message": "Fejlbesked:",
    "additionalInformation": "Yderligere information:",
    "errors": {
      "1": "Mangler nødvendig information",
      "2": "Indtastet data er ikke unik",
      "3": "Indtastet data er ikke gyldig",
      "4": "Forkert filformat",
      "5": "Forkerte dimensioner",
      "6": "Billedets fil er for stor",
      "7": "Findes ikke",
      "100": "Du har ikke de nødvendige rettigheder",
      "101": "Denne bruger kan ikke slettes",
      "102": "Denne rolle kan ikke fjernes",
      "103": "Denne applikation kan ikke slettes",
      "104": "Denne organisation kan ikke slettes",
      "105": "Denne rolle kan ikke slettes",
      "403": "Du er ikke logget ind",
      "default": "Der skete en ukendt fejl"
    }
   }
}
</i18n>

<script>
import getStartingLocale from "@/locales/utils/getStartingLocale";
import Vue from "vue";

// This component is written using pure javascript, and options api due to annoying il8n errors and incompatilibies with vue 2.0.

export default Vue.extend({
  props: {
    error: {
      type: Object,
      required: false,
    },
  },
  data: function () {
    return {
      expanded: false,
    };
  },
  computed: {
    errorTitle: function () {
      switch (this.$props.error?.errorCode) {
        case 1:
          return "errors.1";
        case 2:
          return "errors.2";
        case 3:
          return "errors.3";
        case 4:
          return "errors.4";
        case 5:
          return "errors.5";
        case 6:
          return "errors.6";
        case 7:
          return "errors.7";
        case 100:
          return "errors.100";
        case 101:
          return "errors.101";
        case 102:
          return "errors.102";
        case 103:
          return "errors.103";
        case 104:
          return "errors.104";
        case 105:
          return "errors.105";
        case 403:
          return "errors.403";
        default:
          return "errors.default";
      }
    },
  },
  created: function () {
    this.$i18n.locale = getStartingLocale();
  },
});
</script>
