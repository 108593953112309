











import { useResult } from "@vue/apollo-composable";
import { defineComponent, onMounted, watch } from "@vue/composition-api";
import Navigationbar from "./components/Navigationbar.vue";
import useOidc from "./composables/useOidc";
import useProfile from "./composables/useProfile";
import { useGetUserProfileQuery } from "./graphql/types";
import useGlobalLoading from "./composables/useGlobalLoading";
import Splash from "@/components/Splash.vue";
import Constants from "./constants";
import {
  checkForDmpNotifications,
  markAsShown,
} from "./utilities/dmp-notification.helper";

export default defineComponent({
  components: { Navigationbar, Splash },
  setup(_, { root }) {
    const { user } = useOidc();
    const { result } = useGetUserProfileQuery(() => ({
      enabled: user.value != null,
      fetchPolicy: "no-cache",
    }));
    let isFetchingDmpNotification = false;

    const { setProfile } = useProfile();

    watch(
      () => useResult(result).value,
      (newValue) => {
        setProfile(newValue);
      },
      { immediate: true }
    );

    watch(
      () => user,
      (newValue) => {
        if (newValue && !isFetchingDmpNotification) {
          isFetchingDmpNotification = true;
          checkForDmpNotifications(
            Constants.dmpNotificationUri,
            root.$bvToast,
            root.$createElement
          );
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      window.hideToast = (id: string) => {
        root.$bvToast.hide(id);
        markAsShown(+id);
      };
    });

    return {
      isLoading: useGlobalLoading().isLoading,
    };
  },
});
