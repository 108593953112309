import Vue from "vue";
import getStartingLocale from "../locales/utils/getStartingLocale";
import { DateTime } from "luxon";

Vue.filter("formatDate", function (value: string): string | undefined {
  if (value) {
    const locale: string = getStartingLocale() || "en";
    return DateTime.fromISO(value, { zone: "utc" })
      .setLocale(locale)
      .setZone(DateTime.local().zoneName)
      .toFormat("D");
  }
});

Vue.filter("formatDateTime", function (value: string): string | undefined {
  if (value) {
    const locale: string = getStartingLocale() || "en";
    return DateTime.fromISO(value, { zone: "utc" })
      .setLocale(locale)
      .setZone(DateTime.local().zoneName)
      .toFormat("f");
  }
});
