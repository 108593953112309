import useGlobalLoading from "@/composables/useGlobalLoading";
import useOidc from "@/composables/useOidc";
import useProfile from "@/composables/useProfile";
import Constants from "@/constants";
import { watch } from "@vue/composition-api";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

function usePublicRoutes(): Array<RouteConfig> {
  return [
    {
      path: "",
    },
    {
      path: "*",
      name: "404",
      component: () => import("@/views/404.vue"),
    },
    {
      path: "*",
      name: "403",
      component: () => import("@/views/403.vue"),
    },

    {
      path: "/confirm",
      name: "ConfirmEmail",
      meta: {
        isPublic: true,
      },
      props: (route) => ({ ...route.query }),
      component: () => import("@/views/Self-service/ConfirmEmail.vue"),
    },
  ];
}

function useSelfServiceRoutes(): Array<RouteConfig> {
  return [
    {
      path: "/profile",
      name: "Profile",
      component: () => import("@/views/Profile/Profile.vue"),
    },
    {
      path: "/change-email",
      name: "ChangeEmail",
      component: () => import("@/views/Self-service/ChangeEmail.vue"),
    },
    {
      path: "/confirm-link-user",
      name: "ConfirmLinkUser",
      props: (route) => ({ ...route.query }),
      component: () => import("@/views/Self-service/ConfirmLinkUser.vue"),
    },
  ];
}

function useOrganizationRoutes(): Array<RouteConfig> {
  return [
    {
      path: "/organizations",
      name: "Organizations",
      meta: {
        includeInNavigationBar: () => true,
        localizedName: "organizations.title",
        roleRequirements: [
          Constants.BS_CENTRAL_ADMIN,
          Constants.BS_LOCAL_ADMIN,
          Constants.BS_SUPPORT_ADMIN,
          Constants.BS_READER,
          Constants.BS_TECHNICAL_ADMIN,
        ],
      },
      component: () => import("@/views/Organizations/Organizations.vue"),
      children: [
        {
          path: "new",
          name: "NewOrganization",
          meta: {
            needsParent: true,
            roleRequirements: [
              Constants.BS_CENTRAL_ADMIN,
              Constants.BS_TECHNICAL_ADMIN,
            ],
          },
          props: true,
          component: () => import("@/views/Organizations/Organization.vue"),
        },
        {
          path: ":id",
          name: "Organization",
          meta: {
            needsParent: true,
            roleRequirements: [
              Constants.BS_CENTRAL_ADMIN,
              Constants.BS_LOCAL_ADMIN,
              Constants.BS_SUPPORT_ADMIN,
              Constants.BS_READER,
              Constants.BS_APP_ADMIN,
              Constants.BS_TECHNICAL_ADMIN,
            ],
          },
          props: true,
          component: () => import("@/views/Organizations/Organization.vue"),
        },
      ],
    },
  ];
}

function useTestUsersRoutes(): Array<RouteConfig> {
  return [
    {
      path: "/test-users",
      name: "TestUsers",
      component: () => import("@/views/TestUsers/TestUsers.vue"),
      meta: {
        roleRequirements: [
          Constants.BS_CENTRAL_ADMIN,
          Constants.BS_SUPPORT_ADMIN,
        ],
        includeInNavigationBar: () => Constants.isShowTestUserTab,
        localizedName: "test_users.title",
      },
      children: [
        {
          path: "new",
          name: "NewTestUser",
          meta: {
            needsParent: true,
            roleRequirements: [Constants.BS_CENTRAL_ADMIN],
          },
          component: () => import("@/views/TestUsers/CreateTestUser.vue"),
        },
        {
          path: ":id",
          meta: {
            needsParent: true,
            roleRequirements: [
              Constants.BS_CENTRAL_ADMIN,
              Constants.BS_LOCAL_ADMIN,
              Constants.BS_SUPPORT_ADMIN,
              Constants.BS_APP_ADMIN,
            ],
          },
          name: "TestUser",
          props: true,
          component: () => import("@/views/Users/User.vue"),
        },
      ],
    },
  ];
}

const routes: Array<RouteConfig> = [
  ...useSelfServiceRoutes(),
  ...useOrganizationRoutes(),
  ...useTestUsersRoutes(),
  {
    path: "/users",
    name: "Users",
    component: () => import("@/views/Users/Users.vue"),
    meta: {
      roleRequirements: [
        Constants.BS_CENTRAL_ADMIN,
        Constants.BS_LOCAL_ADMIN,
        Constants.BS_SUPPORT_ADMIN,
        Constants.BS_READER,
        Constants.BS_APP_ADMIN,
      ],
      includeInNavigationBar: () => true,
      localizedName: "users.title",
    },
    children: [
      {
        path: "assign",
        name: "Assign",
        meta: {
          needsParent: true,
          roleRequirements: [
            Constants.BS_CENTRAL_ADMIN,
            Constants.BS_LOCAL_ADMIN,
            Constants.BS_APP_ADMIN,
          ],
        },
        component: () => import("@/views/Users/AssignRoleOnBehalfOf.vue"),
      },
      {
        path: ":id",
        meta: {
          needsParent: true,
          roleRequirements: [
            Constants.BS_CENTRAL_ADMIN,
            Constants.BS_LOCAL_ADMIN,
            Constants.BS_SUPPORT_ADMIN,
            Constants.BS_APP_ADMIN,
          ],
        },
        name: "User",
        props: true,
        component: () => import("@/views/Users/User.vue"),
      },
      {
        path: "assign-role",
        name: "AssignRoleOnbehalfOf",
        meta: {
          roleRequirements: [
            Constants.BS_CENTRAL_ADMIN,
            Constants.BS_LOCAL_ADMIN,
          ],
        },
        component: () => import("@/views/Users/AssignRoleOnBehalfOf.vue"),
      },
    ],
  },
  {
    path: "/applications",
    name: "Applications",
    component: () => import("@/views/Applications/Applications.vue"),
    meta: {
      roleRequirements: [
        Constants.BS_CENTRAL_ADMIN,
        Constants.BS_LOCAL_ADMIN,
        Constants.BS_SUPPORT_ADMIN,
        Constants.BS_READER,
        Constants.BS_APP_ADMIN,
        Constants.BS_TECHNICAL_ADMIN,
      ],
      includeInNavigationBar: () => true,
      localizedName: "applications.title",
    },
    children: [
      {
        path: "new",
        name: "NewApplication",
        meta: {
          needsParent: true,
          roleRequirements: [
            Constants.BS_CENTRAL_ADMIN,
            Constants.BS_TECHNICAL_ADMIN,
          ],
        },
        component: () => import("@/views/Applications/Application.vue"),
      },
      {
        path: ":id",
        name: "Application",
        props: true,
        meta: {
          needsParent: true,
          roleRequirements: [
            Constants.BS_CENTRAL_ADMIN,
            Constants.BS_LOCAL_ADMIN,
            Constants.BS_READER,
            Constants.BS_SUPPORT_ADMIN,
            Constants.BS_APP_ADMIN,
            Constants.BS_TECHNICAL_ADMIN,
          ],
        },
        component: () => import("@/views/Applications/Application.vue"),
      },
    ],
  },
  {
    path: "/roles",
    name: "Roles",
    component: () => import("@/views/Roles/Roles.vue"),
    meta: {
      roleRequirements: [
        Constants.BS_CENTRAL_ADMIN,
        Constants.BS_LOCAL_ADMIN,
        Constants.BS_SUPPORT_ADMIN,
        Constants.BS_APP_ADMIN,
        Constants.BS_READER,
      ],
      includeInNavigationBar: () => true,
      localizedName: "roles.title",
    },
    children: [
      {
        path: "new",
        name: "NewRole",
        meta: {
          needsParent: true,
          roleRequirements: [
            Constants.BS_CENTRAL_ADMIN,
            Constants.BS_APP_ADMIN,
          ],
        },
        component: () => import("@/views/Roles/Role.vue"),
      },
      {
        path: ":id",
        name: "Role",
        props: true,
        meta: {
          needsParent: true,
          roleRequirements: [
            Constants.BS_CENTRAL_ADMIN,
            Constants.BS_LOCAL_ADMIN,
            Constants.BS_SUPPORT_ADMIN,
            Constants.BS_APP_ADMIN,
          ],
        },
        component: () => import("@/views/Roles/Role.vue"),
      },
    ],
  },
  {
    path: "/claims",
    name: "Claims",
    component: () => import("@/views/Claims/Claims.vue"),
    meta: {
      localizedName: "common.claims",
    },
  },
  ...usePublicRoutes(),
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL,
});

const { profile, roles } = useProfile();

router.beforeEach(async (to, from, next) => {
  // When oidc has been initialized, and is logged in, we want to ensure that the user is downloaded from the graph before continuing.
  if (
    useOidc().user.value != null &&
    from.path != Constants.oidc_callback_uri
  ) {
    if (profile.value == null) useGlobalLoading().startLoading();
    const promise = new Promise<void>((resolve, reject) => {
      if (profile.value == null) {
        const watcher = watch(
          () => profile.value,
          (newValue) => {
            if (newValue) {
              resolve();

              // Remove the watch
              watcher();
            }
          },
          { immediate: true }
        );
      } else {
        resolve();
      }
    });

    await promise;
    useGlobalLoading().stopLoading();
    next();
  } else {
    next();
  }
});

router.beforeEach(async (to, from, next) => {
  // If user is logged in, then we know above hook ensures profile is downloaded, so we want to check if he can access page.
  if (profile.value != null && to.meta?.roleRequirements != null) {
    const fulfilled = to.meta.roleRequirements.some(
      (role) =>
        roles.value?.some((element) => element.attribute == role) ?? false
    );
    // This ensures that user is redirected to 403 page
    if (!fulfilled)
      return next({ name: "403", params: { 0: to.fullPath }, replace: true });
  }

  if (from.path == "/" && to.meta?.needsParent) {
    next(false);
    await router.push({
      name: to.matched[to.matched.length - 2].name,
      params: to.params,
    });
    await router.push({ name: to.name ?? undefined, params: to.params });
  } else {
    next();
  }
});

export default router;
