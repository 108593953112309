import Oidc, { User } from "oidc-client";
import Constants, { OIDC_RESPONSE_TYPE, OIDC_SCOPE } from "@/constants";
import VueRouter, { RouteRecord } from "vue-router";
import { h, ref, watch } from "@vue/composition-api";
// import useUser from "./useUser";

import VueCompositionAPI from "@vue/composition-api";
import Vue from "vue";
import useProfile from "./useProfile";
import useGlobalLoading from "./useGlobalLoading";
Vue.use(VueCompositionAPI);

const oidcManager = ref<Oidc.UserManager | null>(null);
const user = ref(null as User | null);

export default function () {
  async function initialize(
    authority: string,
    clientId: string,
    router: VueRouter
  ) {
    useGlobalLoading().startLoading();
    oidcManager.value = new Oidc.UserManager({
      authority: authority,
      client_id: clientId,
      redirect_uri: `${window.location.origin}${Constants.oidc_callback_uri}`,
      post_logout_redirect_uri: `${window.location.origin}${Constants.oidc_logout_uri}`,
      response_type: OIDC_RESPONSE_TYPE,
      scope: OIDC_SCOPE,
      monitorSession: false,
      automaticSilentRenew: true,
      userStore: new Oidc.WebStorageStateStore({
        store: window.sessionStorage,
      }),
    });

    user.value = await oidcManager.value?.getUser();
    handleManagerEvents(oidcManager.value, router);
    setupRouter(router);
    if (user.value) {
      useGlobalLoading().stopLoading();
    }
  }

  function handleManagerEvents(mgr: Oidc.UserManager, router: VueRouter) {
    mgr.events.addUserLoaded((newUser) => {
      user.value = newUser;
    });

    mgr.events.addUserUnloaded(() => {
      user.value = null;
    });

    mgr.events.addAccessTokenExpired(() => {
      logOut();
    });

    mgr.events.addUserSignedOut(() => {
      logOut();
    });
  }

  function setupRouter(router: VueRouter) {
    router.addRoute({
      path: Constants.oidc_callback_uri,
      meta: {
        isPublic: true,
      },
      component: {
        render: () => h("div"),
        created() {
          oidcManager.value?.signinRedirectCallback().then((data) => {
            const { profile, userHome } = useProfile();
            watch(
              () => profile.value,
              (newValue) => {
                if (newValue == null) return;
                const redirect = data.state ? data.state.to : null;
                if (redirect == "" || redirect == "/") {
                  router.replace(userHome.value);
                } else {
                  router.replace(redirect);
                }
                useGlobalLoading().stopLoading();
              },
              { immediate: true }
            );
          });
        },
      },
    });

    router.addRoute({
      path: Constants.oidc_logout_uri,
      meta: {
        isPublic: true,
      },
      component: () => import("@/views/LoggedOut.vue"),
    });

    router.beforeEach(async (to, from, next) => {
      if (to.matched.length > 0 && to.matched[0].meta.isPublic) {
        useGlobalLoading().stopLoading();
        return next();
      } else if (user.value && !user.value.expired) {
        return next();
      } else {
        await oidcManager.value?.signinRedirect({
          state: { to: to.fullPath },
        });
      }
    });
  }

  function logOut() {
    const token = user.value?.id_token;
    oidcManager.value
      ?.signoutRedirect({ id_token_hint: token })
      .then(function (resp) {
        console.log("signed out", resp);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  function forceLogin(to: RouteRecord) {
    oidcManager.value?.signinRedirect({ state: { to } });
  }

  return {
    initialize,
    logOut,
    forceLogin,
    user,
  };
}
