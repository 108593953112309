
































import Spinner from "@/components/Spinner.vue";
import useOidc from "@/composables/useOidc";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    Spinner,
  },
  setup() {
    const { user } = useOidc();
    return {
      firstStep: computed(() => user.value == null),
    };
  },
});
