import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEllipsisV,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faTimes,
  faExpandAlt,
  faPlus,
  faTrashAlt,
  faGlobe,
  faBuilding,
  faCheck,
  faThumbsUp,
  faDatabase,
  faShareAlt,
  faPen,
  faCompressAlt,
  faEnvelope,
  faUser,
  faInfoCircle,
  faClock,
  faLock,
  faCalendarAlt,
  faSpinner,
  faCopy,
  faSort,
  faSortDown,
  faSortUp,
  faExclamationTriangle,
  faEnvelopeOpen,
  faRedo,
  faBars,
  faCog,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

// FaSolid
library.add(
  faChevronUp,
  faExpandAlt,
  faChevronDown,
  faTimes,
  faEllipsisV,
  faPlus,
  faTrashAlt,
  faGlobe,
  faBuilding,
  faCheck,
  faThumbsUp,
  faDatabase,
  faShareAlt,
  faChevronRight,
  faPen,
  faCompressAlt,
  faEnvelope,
  faUser,
  faInfoCircle,
  faClock,
  faChevronLeft,
  faLock,
  faCalendarAlt,
  faSpinner,
  faCopy,
  faSort,
  faSortUp,
  faSortDown,
  faExclamationTriangle,
  faEnvelopeOpen,
  faRedo,
  faBars,
  faCog,
  faQuestion
);

import { dom } from "@fortawesome/fontawesome-svg-core";

dom.watch();
